import {
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { fetchApi, getApiData } from "@/lib/api.ts";

interface Session {
  id: string;
  tenantId: string;
  name: string;
  email: string;
  role: "user" | "admin" | "1337";
}

export function useSessionContext() {
  const location = useLocation();

  const { data, isLoading, error } = useQuery<Session>({
    queryKey: ["users/me"],
    queryFn: () => fetchApi.get("/users/me").then(getApiData<Session>),
    refetchOnWindowFocus: () => {
      return !location.pathname.startsWith("/auth");
    },
    staleTime: 60000,
  });

  const isLoggedIn = !error && !!data;

  return {
    session: data,
    isLoading,
    isLoggedIn,
  };
}

export function useSuspenseSessionContext() {
  const location = useLocation();

  const { data, isLoading, error } = useSuspenseQuery<Session>({
    queryKey: ["users/me"],
    queryFn: () => fetchApi.get("/users/me").then(getApiData<Session>),
    refetchOnWindowFocus: () => {
      return !location.pathname.startsWith("/auth");
    },
    staleTime: 60000,
  });

  const isLoggedIn = !error && !!data;

  return {
    session: data,
    isLoading,
    isLoggedIn,
  };
}

export function useLogoutMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["/auth/logout"],
    mutationFn: async () => fetchApi.post("/auth/logout"),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["users/me"],
      });
    },
  });
}
