import { useMultiStepFormContext } from "@/hooks/use-multistep.ts";
import { Box } from "@/components/tw-ui/box";
import { Building, MapPin, FileText, Bank } from "@phosphor-icons/react";
import { useIsMutating } from "@tanstack/react-query";
import { PendingButton } from "@/components/pending-button.tsx";

export function CreateCompanyStep6() {
  const { form } = useMultiStepFormContext();
  const isMutating = useIsMutating({
    mutationKey: ["create-company"],
    exact: true,
  });

  const { isValid } = form.formState;

  if (!isValid) {
    return (
      <div>
        <h3>Por favor, preencha todos os campos</h3>
      </div>
    );
  }

  const values = form.getValues();

  const services = [];
  if (values?.services?.sendServiceInvoice) services.push("Emissão de NFSe");
  if (values?.services?.sendProductInvoice) services.push("Emissão de NFe");
  if (values?.services?.receiveProductInvoice) services.push("Captura de NFe");

  return (
    <div
      className="flex flex-col gap-4 h-full"
      onSubmit={form.handleSubmit(console.log)}
    >
      <div className="grid gap-4">
        <Box>
          <div className="flex items-center gap-2 mb-2">
            <Building className="h-5 w-5 text-primary" weight="duotone" />
            <h3 className="font-medium">Dados da Empresa</h3>
          </div>
          <div className="space-y-1 pl-7">
            <p>{values?.company?.name}</p>
            <p className="text-sm text-muted-foreground">
              CNPJ: {values?.company?.document}
            </p>
          </div>
        </Box>

        <Box>
          <div className="flex items-center gap-2 mb-2">
            <MapPin className="h-5 w-5 text-primary" weight="duotone" />
            <h3 className="font-medium">Localização</h3>
          </div>
          <div className="pl-7 text-sm text-muted-foreground">
            <p>
              {values?.address?.street}, {values?.address?.number}
              {values?.address?.complement && ` - ${values.address.complement}`}
            </p>
            <p>
              {values?.address?.district} - {values?.address?.cityCode}/
              {values?.address?.stateCode}
            </p>
          </div>
        </Box>

        <Box>
          <div className="flex items-center gap-2 mb-2">
            <FileText className="h-5 w-5 text-primary" weight="duotone" />
            <h3 className="font-medium">Serviços</h3>
          </div>
          <div className="pl-7 text-sm text-muted-foreground">
            {services.length > 0 ? (
              <ul className="list-disc list-inside">
                {services.map((service) => (
                  <li key={service}>{service}</li>
                ))}
              </ul>
            ) : (
              <p>Nenhum serviço de nota fiscal será utilizado</p>
            )}
          </div>
        </Box>

        <Box>
          <div className="flex items-center gap-2 mb-2">
            <Bank className="h-5 w-5 text-primary" weight="duotone" />
            <h3 className="font-medium">Informações Fiscais</h3>
          </div>
          <div className="pl-7 text-sm text-muted-foreground space-y-1">
            <p>
              Regime Tributário:{" "}
              {values?.tributes?.tributaryRegime === 1
                ? "Simples Nacional"
                : values?.tributes?.tributaryRegime === 2
                  ? "Simples Nacional - Excesso de Sublimite"
                  : "Regime Normal"}
            </p>
            {values?.tributes?.municipalRegistration && (
              <p>IM: {values.tributes.municipalRegistration}</p>
            )}
            {values?.tributes?.stateRegistration && (
              <p>IE: {values.tributes.stateRegistration}</p>
            )}
          </div>
        </Box>
      </div>

      <PendingButton type="submit" isPending={!!isMutating}>
        Concluir
      </PendingButton>
    </div>
  );
}
