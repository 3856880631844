import { PatternFormat } from "react-number-format";
import { Input } from "@/components/ui/input.tsx";
import { forwardRef, type ComponentPropsWithoutRef } from "react";

type Props = Omit<
  ComponentPropsWithoutRef<typeof PatternFormat>,
  "customInput"
>;

export const PatternInput = forwardRef<HTMLInputElement, Props>(
  (props, ref) => {
    return <PatternFormat {...props} customInput={Input} getInputRef={ref} />;
  }
);

PatternInput.displayName = "PatternInput";
