import type { ReactNode } from "react";
import { Link } from "react-router-dom";

interface DocumentCardInfoProps {
  label: string;
  value: string;
  icon: ReactNode;
  linkTo?: string;
}
export function DocumentCardInfo({
  label,
  value,
  icon,
  linkTo,
}: DocumentCardInfoProps) {
  const Wrapper = linkTo
    ? ({ children }: { children: ReactNode }) => (
        <Link to={linkTo}>{children}</Link>
      )
    : ({ children }: { children: ReactNode }) => <div>{children}</div>;

  return (
    <Wrapper>
      <div className="flex items-center gap-4 rounded-lg border p-4 hover:bg-secondary/60 hover:cursor-pointer hover:shadow-sm transition-all duration-200">
        {icon}
        <div>
          <p className="text-2xl font-bold">{value}</p>
          <p className="text-sm text-muted-foreground">{label}</p>
        </div>
      </div>
    </Wrapper>
  );
}
