import { useFormContext } from "react-hook-form";
import type { NewProductInvoice } from "@shared/schemas/invoices/nfe/new_product.ts";
import { FormFieldSimple } from "@/components/ui/form.tsx";
import { DatePicker } from "@/components/ui/date-picker.tsx";
import { Input } from "@/components/ui/input.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";
import {
  NFeConsumidorFinal,
  NFeFinalidadeEmissao,
  NFeLocalDestino,
  NFePresencaComprador,
  NFeTipoDocumento,
} from "@shared/schemas/invoices/nfe/focus/focus_product.ts";

export function NewInvoiceProductDetails() {
  const form = useFormContext<NewProductInvoice>();

  return (
    <div className="grid grid-cols-6 gap-3">
      <FormFieldSimple
        control={form.control}
        name="details.invoiceNumber"
        label="Número da NF-e"
        description="Número da NF-e (Este campo é preenchido automaticamente)"
        render={({ field }) => (
          <Input
            disabled
            {...field}
            placeholder={!field.value ? "{automático}" : undefined}
          />
        )}
      />
      <FormFieldSimple
        control={form.control}
        name="invoice.issuedAt"
        label="Data de Emissão"
        required
        render={({ field }) => (
          <DatePicker
            setDate={(date) => field.onChange(date?.toISOString())}
            date={new Date(field.value)}
          />
        )}
      />
      <FormFieldSimple
        control={form.control}
        name="details.enterExitAt"
        label="Data de Entrada/Saída"
        render={({ field }) => (
          <DatePicker
            setDate={(date) => field.onChange(date?.toISOString())}
            date={field.value ? new Date(field.value) : undefined}
          />
        )}
      />
      <FormFieldSimple
        control={form.control}
        name="details.documentType"
        label="Tipo do Documento"
        required
        render={({ field }) => (
          <Select value={field.value} onValueChange={field.onChange}>
            <SelectTrigger>
              <SelectValue placeholder="Selecione" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={NFeTipoDocumento.NOTA_FISCAL_ENTRADA}>
                0 - Nota fiscal de entrada
              </SelectItem>
              <SelectItem value={NFeTipoDocumento.NOTA_FISCAL_SAIDA}>
                1 - Nota fiscal de saída
              </SelectItem>
            </SelectContent>
          </Select>
        )}
      />
      <FormFieldSimple
        control={form.control}
        name="details.destination"
        label="Destino da Operação"
        required
        render={({ field }) => (
          <Select value={field.value} onValueChange={field.onChange}>
            <SelectTrigger>
              <SelectValue placeholder="Selecione" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={NFeLocalDestino.OPERACAO_INTERNA}>
                1 - Operação interna
              </SelectItem>
              <SelectItem value={NFeLocalDestino.OPERACAO_INTERESTADUAL}>
                2 - Operação interestadual
              </SelectItem>
              <SelectItem value={NFeLocalDestino.OPERACAO_COM_EXTERIOR}>
                3 - Operação com exterior
              </SelectItem>
            </SelectContent>
          </Select>
        )}
      />
      <FormFieldSimple
        control={form.control}
        name="details.purposeOfEmission"
        label="Finalidade de Emissão"
        required
        render={({ field }) => (
          <Select value={field.value} onValueChange={field.onChange}>
            <SelectTrigger>
              <SelectValue placeholder="Selecione" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={NFeFinalidadeEmissao.NOTA_NORMAL}>
                1 - Nota Normal
              </SelectItem>
              <SelectItem value={NFeFinalidadeEmissao.NOTA_COMPLEMENTAR}>
                2 - Nota Complementar
              </SelectItem>
              <SelectItem value={NFeFinalidadeEmissao.NOTA_DE_AJUSTE}>
                3 - Nota de Ajuste
              </SelectItem>
              <SelectItem value={NFeFinalidadeEmissao.DEVOLUCAO_DE_MERCADORIA}>
                4 - Devolução de Mercadoria
              </SelectItem>
            </SelectContent>
          </Select>
        )}
      />
      <FormFieldSimple
        control={form.control}
        name="details.endConsumer"
        label="Consumidor final"
        required
        render={({ field }) => (
          <Select value={field.value} onValueChange={field.onChange}>
            <SelectTrigger>
              <SelectValue placeholder="Selecione" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={NFeConsumidorFinal.CONSUMIDOR_FINAL}>
                1 - Sim
              </SelectItem>
              <SelectItem value={NFeConsumidorFinal.CONSUMIDOR_NORMAL}>
                0 - Não
              </SelectItem>
            </SelectContent>
          </Select>
        )}
      />
      <FormFieldSimple
        control={form.control}
        name="details.buyerPresence"
        label="Presença do comprador"
        required
        render={({ field }) => (
          <Select value={field.value} onValueChange={field.onChange}>
            <SelectTrigger>
              <SelectValue placeholder="Selecione" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={NFePresencaComprador.NAO_SE_APLICA}>
                0 - Não se aplica
              </SelectItem>
              <SelectItem value={NFePresencaComprador.OPERACAO_PRESENCIAL}>
                1 - Presencial
              </SelectItem>
              <SelectItem
                value={NFePresencaComprador.OPERACAO_NAO_PRESENCIAL_INTERNET}
              >
                2 - Internet
              </SelectItem>
              <SelectItem
                value={
                  NFePresencaComprador.OPERACAO_NAO_PRESENCIAL_TELEATENDIMENTO
                }
              >
                3 - Teleatendimento
              </SelectItem>
              <SelectItem
                value={NFePresencaComprador.NFC_E_COM_ENTREGA_EM_DOMICILIO}
              >
                4 - NFC-e com entrega em domicílio
              </SelectItem>
              <SelectItem
                value={
                  NFePresencaComprador.OPERACAO_PRESENCIAL_FORA_DO_ESTABELECIMENTO
                }
              >
                5 - Fora do estabelecimento
              </SelectItem>
              <SelectItem
                value={NFePresencaComprador.OPERACAO_NAO_PRESENCIAL_OUTROS}
              >
                9 - Outros
              </SelectItem>
            </SelectContent>
          </Select>
        )}
      />
      <div className="col-span-4" />
      <FormFieldSimple
        className="col-span-6"
        control={form.control}
        name="details.operationNature"
        label="Natureza da Operação"
        required
        render={({ field }) => (
          <Input {...field} placeholder="Ex.: Venda de produtos" />
        )}
      />
    </div>
  );
}
