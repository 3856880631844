import type { CreateServiceInvoice } from "@shared/types/invoices/service.ts";
import type { ColumnDef } from "@tanstack/react-table";
import { DataTableColumnHeader } from "@/components/table/data-table-column-header.tsx";
import { Check } from "lucide-react";
import { formatCurrency } from "@/lib/currency.ts";

import { format } from "date-fns";
import { formatCNPJ } from "@shared/format.ts";

interface SuccessDataTableProps {
  status: "success" | "error";
  invoice: CreateServiceInvoice;
}

export const successNfseColumns: ColumnDef<SuccessDataTableProps>[] = [
  {
    accessorKey: "status",
    header: ({ column }) => (
      <DataTableColumnHeader title="Status" column={column} />
    ),
    cell: () => {
      return <Check className="ml-2 size-5 text-green-500" />;
    },
  },
  {
    accessorKey: "invoice.issuedAt",
    header: ({ column }) => (
      <DataTableColumnHeader title="Data de Emissão" column={column} />
    ),
    cell: ({ row }) => {
      return <div>{format(row.original.invoice.issuedAt, "dd/MM/yyyy")} </div>;
    },
  },
  {
    accessorKey: "invoice.issuer.document",
    header: ({ column }) => (
      <DataTableColumnHeader
        className={"w-[180px]"}
        title="CNPJ Emissor"
        column={column}
      />
    ),
    cell: ({ row }) => {
      return <div>{formatCNPJ(row.original.invoice.issuer.document)}</div>;
    },
  },
  {
    accessorKey: "invoice.issuer.operationNature",
    header: ({ column }) => (
      <DataTableColumnHeader
        className={"w-[180px]"}
        title="Natureza Operação"
        column={column}
      />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.issuer.operationNature}</div>;
    },
  },
  {
    accessorKey: "invoice.issuer.specialRegimeTributary",
    header: ({ column }) => (
      <DataTableColumnHeader
        title="Regime Especial Tributário"
        column={column}
      />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.issuer.specialRegimeTributary}</div>;
    },
  },
  {
    accessorKey: "invoice.issuer.culturalSupporter",
    header: ({ column }) => (
      <DataTableColumnHeader title="Incentivador Cultural" column={column} />
    ),
    cell: ({ row }) => {
      return (
        <div>
          {row.original.invoice.issuer.culturalSupporter ? "Sim" : "Não"}
        </div>
      );
    },
  },

  {
    accessorKey: "invoice.recipient.document",
    header: ({ column }) => (
      <DataTableColumnHeader
        className={"w-[180px]"}
        title="CPF/CNPJ"
        column={column}
      />
    ),
    cell: ({ row }) => {
      return <div>{formatCNPJ(row.original.invoice.recipient.document)}</div>;
    },
  },
  {
    accessorKey: "invoice.recipient.municipalRegistration",
    header: ({ column }) => (
      <DataTableColumnHeader title="Inscrição Municipal" column={column} />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.recipient.municipalRegistration}</div>;
    },
  },
  {
    accessorKey: "invoice.recipient.stateRegistration",
    header: ({ column }) => (
      <DataTableColumnHeader title="Inscrição Estadual" column={column} />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.recipient.stateRegistration}</div>;
    },
  },
  {
    accessorKey: "invoice.recipient.companyName",
    header: ({ column }) => (
      <DataTableColumnHeader
        className={"w-[180px]"}
        title="Razão Social"
        column={column}
      />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.recipient.companyName}</div>;
    },
  },
  {
    accessorKey: "invoice.recipient.email",
    header: ({ column }) => (
      <DataTableColumnHeader title="Email" column={column} />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.recipient.email}</div>;
    },
  },
  {
    accessorKey: "invoice.recipient.phone",
    header: ({ column }) => (
      <DataTableColumnHeader title="Telefone" column={column} />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.recipient.phone}</div>;
    },
  },
  {
    accessorKey: "invoice.recipient.address.street",
    header: ({ column }) => (
      <DataTableColumnHeader
        className={"min-w-[250px]"}
        title="Logradouro"
        column={column}
      />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.recipient.address.street}</div>;
    },
  },
  {
    accessorKey: "invoice.recipient.address.number",
    header: ({ column }) => (
      <DataTableColumnHeader title="Numero" column={column} />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.recipient.address.number}</div>;
    },
  },
  {
    accessorKey: "invoice.recipient.address.complement",
    header: ({ column }) => (
      <DataTableColumnHeader
        className={"w-[130px]"}
        title="Complemento"
        column={column}
      />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.recipient.address.complement}</div>;
    },
  },
  {
    accessorKey: "invoice.recipient.address.district",
    header: ({ column }) => (
      <DataTableColumnHeader
        className={"w-[120px]"}
        title="Bairro"
        column={column}
      />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.recipient.address.district}</div>;
    },
  },
  {
    accessorKey: "invoice.recipient.address.cityCode",
    header: ({ column }) => (
      <DataTableColumnHeader title="Código do Município" column={column} />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.recipient.address.cityCode}</div>;
    },
  },
  {
    accessorKey: "invoice.recipient.address.zipCode",
    header: ({ column }) => (
      <DataTableColumnHeader title="CEP" column={column} />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.recipient.address.zipCode}</div>;
    },
  },
  {
    accessorKey: "invoice.recipient.address.state",
    header: ({ column }) => (
      <DataTableColumnHeader title="UF" column={column} />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.recipient.address.state}</div>;
    },
  },

  {
    accessorKey: "invoice.service.value",
    header: ({ column }) => (
      <DataTableColumnHeader title="Valor do Serviço (R$)" column={column} />
    ),
    cell: ({ row }) => {
      return (
        <div>{formatCurrency(row.original.invoice.service.value, "BRL")}</div>
      );
    },
  },
  {
    accessorKey: "invoice.service.deductions",
    header: ({ column }) => (
      <DataTableColumnHeader title="Valor deduções (R$)" column={column} />
    ),
    cell: ({ row }) => {
      return (
        <div>
          {formatCurrency(row.original.invoice.service.deductions, "BRL")}
        </div>
      );
    },
  },
  {
    accessorKey: "invoice.service.pis",
    header: ({ column }) => (
      <DataTableColumnHeader title="Valor PIS (R$)" column={column} />
    ),
    cell: ({ row }) => {
      return (
        <div>{formatCurrency(row.original.invoice.service.pis, "BRL")}</div>
      );
    },
  },
  {
    accessorKey: "invoice.service.cofins",
    header: ({ column }) => (
      <DataTableColumnHeader title="Valor COFINS (R$)" column={column} />
    ),
    cell: ({ row }) => {
      return (
        <div>{formatCurrency(row.original.invoice.service.cofins, "BRL")}</div>
      );
    },
  },
  {
    accessorKey: "invoice.service.inss",
    header: ({ column }) => (
      <DataTableColumnHeader title="Valor INSS (R$)" column={column} />
    ),
    cell: ({ row }) => {
      return (
        <div>{formatCurrency(row.original.invoice.service.inss, "BRL")}</div>
      );
    },
  },
  {
    accessorKey: "invoice.service.ir",
    header: ({ column }) => (
      <DataTableColumnHeader title="Valor IR (R$)" column={column} />
    ),
    cell: ({ row }) => {
      return (
        <div>{formatCurrency(row.original.invoice.service.ir, "BRL")}</div>
      );
    },
  },
  {
    accessorKey: "invoice.service.csll",
    header: ({ column }) => (
      <DataTableColumnHeader title="Valor CSLL (R$)" column={column} />
    ),
    cell: ({ row }) => {
      return (
        <div>{formatCurrency(row.original.invoice.service.csll, "BRL")}</div>
      );
    },
  },
  {
    accessorKey: "invoice.service.issRetained",
    header: ({ column }) => (
      <DataTableColumnHeader title="Retenção ISS" column={column} />
    ),
    cell: ({ row }) => {
      return (
        <div>{row.original.invoice.service.issRetained ? "Sim" : "Não"}</div>
      );
    },
  },
  {
    accessorKey: "invoice.service.issValue",
    header: ({ column }) => (
      <DataTableColumnHeader title="Valor ISS (R$)" column={column} />
    ),
    cell: ({ row }) => {
      return (
        <div>
          {formatCurrency(row.original.invoice.service.issValue, "BRL")}
        </div>
      );
    },
  },
  {
    accessorKey: "invoice.service.issValueRetained",
    header: ({ column }) => (
      <DataTableColumnHeader title="Valor ISS Retido (R$)" column={column} />
    ),
    cell: ({ row }) => {
      return (
        <div>
          {formatCurrency(row.original.invoice.service.issValueRetained, "BRL")}
        </div>
      );
    },
  },
  {
    accessorKey: "invoice.service.otherRetentions",
    header: ({ column }) => (
      <DataTableColumnHeader title="Outras Retenções (R$)" column={column} />
    ),
    cell: ({ row }) => {
      return (
        <div>
          {formatCurrency(row.original.invoice.service.otherRetentions, "BRL")}
        </div>
      );
    },
  },
  {
    accessorKey: "invoice.service.baseValue",
    header: ({ column }) => (
      <DataTableColumnHeader title="Valor Base (R$)" column={column} />
    ),
    cell: ({ row }) => {
      return (
        <div>
          {formatCurrency(row.original.invoice.service.baseValue, "BRL")}
        </div>
      );
    },
  },
  {
    accessorKey: "invoice.service.aliquot",
    header: ({ column }) => (
      <DataTableColumnHeader title="Aliquota (%)" column={column} />
    ),
    cell: ({ row }) => {
      return (
        <div>
          {parseFloat(row.original.invoice.service.aliquot || "0").toFixed(2) +
            "%"}
        </div>
      );
    },
  },
  {
    accessorKey: "invoice.service.unconditionalDiscount",
    header: ({ column }) => (
      <DataTableColumnHeader
        title="Desconto Incondicionado (R$)"
        column={column}
      />
    ),
    cell: ({ row }) => {
      return (
        <div>
          {formatCurrency(
            row.original.invoice.service.unconditionalDiscount,
            "BRL"
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "invoice.service.conditionalDiscount",
    header: ({ column }) => (
      <DataTableColumnHeader
        title="Desconto Condicionado (R$)"
        column={column}
      />
    ),
    cell: ({ row }) => {
      return (
        <div>
          {formatCurrency(
            row.original.invoice.service.conditionalDiscount,
            "BRL"
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "invoice.service.item",
    header: ({ column }) => (
      <DataTableColumnHeader title="Item Lista Serviço" column={column} />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.service.item}</div>;
    },
  },
  {
    accessorKey: "invoice.service.cityCode",
    header: ({ column }) => (
      <DataTableColumnHeader
        title="Código da Cidade (Local da Prestação)"
        column={column}
      />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.service.cityCode}</div>;
    },
  },
  {
    accessorKey: "invoice.service.cnae",
    header: ({ column }) => (
      <DataTableColumnHeader title="Código CNAE" column={column} />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.service.cnae}</div>;
    },
  },
  {
    accessorKey: "invoice.service.municipalTaxCode",
    header: ({ column }) => (
      <DataTableColumnHeader
        title="Código Tributário Municipio"
        column={column}
      />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.service.municipalTaxCode}</div>;
    },
  },
  {
    accessorKey: "invoice.service.description",
    header: ({ column }) => (
      <DataTableColumnHeader
        className={"min-w-[150px] max-w-[300px]"}
        title="Descrição"
        column={column}
      />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.service.description}</div>;
    },
  },
  {
    accessorKey: "invoice.service.addressLocation",
    header: ({ column }) => (
      <DataTableColumnHeader title="Tipo de Endereço" column={column} />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.service.addressLocation}</div>;
    },
  },
  {
    accessorKey: "invoice.service.address.street",
    header: ({ column }) => (
      <DataTableColumnHeader
        className={"min-w-[250px]"}
        title="Logradouro (Local da Prestação)"
        column={column}
      />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.service.address?.street}</div>;
    },
  },
  {
    accessorKey: "invoice.service.address.number",
    header: ({ column }) => (
      <DataTableColumnHeader
        title="Número (Local da Prestação)"
        column={column}
      />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.service.address?.number}</div>;
    },
  },
  {
    accessorKey: "invoice.service.address.complement",
    header: ({ column }) => (
      <DataTableColumnHeader
        className={"min-w-[130px]"}
        title="Complemento (Local da Prestação)"
        column={column}
      />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.service.address?.complement}</div>;
    },
  },
  {
    accessorKey: "invoice.service.address.district",
    header: ({ column }) => (
      <DataTableColumnHeader
        className={"min-w-[120px]"}
        title="Bairro (Local da Prestação)"
        column={column}
      />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.service.address?.district}</div>;
    },
  },
];
