import {
  PageContainer,
  PageContent,
  PageDescription,
  PageHeader,
  PageTitle,
} from "@/components/layout/page.tsx";
import { CreateProductInvoiceFloatingOptions } from "@/pages/invoices/product/manage/components/floating-options.tsx";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion.tsx";
import { Building, ChatDots, Package, Truck } from "@phosphor-icons/react";
import { File, User } from "lucide-react";
import type { NewProductInvoice } from "@shared/schemas/invoices/nfe/new_product.ts";
import { productSchema } from "@shared/schemas/invoices/nfe/new_product.ts";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "@/components/ui/form.tsx";
import { NewInvoiceProductDetails } from "@/pages/invoices/product/manage/components/new-invoice-product-details.tsx";
import { NewInvoiceProductIssuer } from "@/pages/invoices/product/manage/components/new-invoice-product-issuer.tsx";
import {
  NFeConsumidorFinal,
  NFeFinalidadeEmissao,
  NFeIndicadorInscricaoEstadual,
  NFeLocalDestino,
  NFeModalidadeFrete,
  NFePresencaComprador,
  NFeTipoDocumento,
} from "@shared/schemas/invoices/nfe/focus/focus_product.ts";
import { RegimeTributary } from "@shared/schemas/company.ts";
import { NewInvoiceProductRecipient } from "@/pages/invoices/product/manage/components/new-invoice-product-recipient.tsx";
import { NewInvoiceProductItems } from "@/pages/invoices/product/manage/components/new-invoice-product-items.tsx";
import { NewInvoiceProductFreight } from "@/pages/invoices/product/manage/components/new-invoice-product-freight.tsx";
import { NewInvoiceProductOthers } from "@/pages/invoices/product/manage/components/new-invoice-product-others.tsx";
import { useMutation } from "@tanstack/react-query";
import { fetchApi } from "@/lib/api.ts";
import { toast } from "sonner";
import { useSearchParams } from "react-router-dom";

export function CreateEditProductInvoice() {
  const [searchParams] = useSearchParams();
  const invoiceId = searchParams.get("invoiceId");

  console.log(invoiceId);

  const form = useForm<NewProductInvoice>({
    resolver: zodResolver(productSchema),
    defaultValues: {
      invoice: {
        issuedAt: new Date().toISOString(),
      },
      details: {
        operationNature: "",
        documentType: NFeTipoDocumento.NOTA_FISCAL_ENTRADA,
        destination: NFeLocalDestino.OPERACAO_INTERNA,
        purposeOfEmission: NFeFinalidadeEmissao.NOTA_NORMAL,
        endConsumer: NFeConsumidorFinal.CONSUMIDOR_FINAL,
        buyerPresence: NFePresencaComprador.OPERACAO_PRESENCIAL,
      },
      issuer: {
        document: "",
        name: "",
        address: {
          street: "",
          number: "",
          complement: "",
          district: "",
          city: "",
          state: "",
          zipCode: "",
          cityCode: "",
        },
        phone: "",
        stateRegistration: "",
        tributaryRegime: RegimeTributary.REGIME_NORMAL,
      },
      recipient: {
        document: "",
        name: "",
        phone: "",
        email: "",
        address: {
          street: "",
          number: "",
          complement: "",
          district: "",
          city: "",
          state: "",
          zipCode: "",
          cityCode: "",
        },
        stateRegistration: "",
        stateIndicator: NFeIndicadorInscricaoEstadual.NAO_CONTRIBUINTE,
      },
      items: [],
      freight: {
        modality: NFeModalidadeFrete.SEM_FRETE,
      },
      others: {
        taxInformation: "",
        complementaryInformation: "",
      },
    },
  });

  const { mutate, isPending } = useMutation({
    mutationKey: ["create-product-invoice"],
    mutationFn: async (data: NewProductInvoice) => {
      return fetchApi.post("/invoices/products/sent", data);
    },
    onError: () => {
      toast.error("Ocorreu um erro ao criar a nota.");
    },
  });

  const submit = form.handleSubmit((data) => {
    mutate(data);
  });

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>
          {invoiceId
            ? "Editando informações de uma NF-e"
            : "Criando uma nova NF-e"}
        </PageTitle>
        <PageDescription>
          Preencha as informações para criar uma nova nota fiscal de produto
          (NF-e)
        </PageDescription>
      </PageHeader>
      <PageContent>
        <Form {...form}>
          <form onSubmit={submit}>
            <CreateProductInvoiceFloatingOptions
              className="fixed bottom-5 left-1/2"
              isNewDraft={!invoiceId}
              isPending={isPending}
            />
            <Accordion type="single" collapsible className="space-y-5">
              <AccordionItem value="details">
                <AccordionTrigger>
                  <div className="flex items-center gap-x-2">
                    <File className="size-5" />
                    <span>Detalhes da Nota</span>
                  </div>
                </AccordionTrigger>
                <AccordionContent className="p-3">
                  <NewInvoiceProductDetails />
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="issuer">
                <AccordionTrigger>
                  <div className="flex items-center gap-x-2">
                    <Building className="size-5" />
                    <span>Dados do Emitente</span>
                  </div>
                </AccordionTrigger>
                <AccordionContent className="p-3">
                  <NewInvoiceProductIssuer />
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="destination">
                <AccordionTrigger>
                  <div className="flex items-center gap-x-2">
                    <User className="size-5" />
                    <span>Dados do Destinatário</span>
                  </div>
                </AccordionTrigger>
                <AccordionContent className="p-3">
                  <NewInvoiceProductRecipient />
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="items">
                <AccordionTrigger>
                  <div className="flex items-center gap-x-2">
                    <Package className="size-5" />
                    <span>Produtos</span>
                  </div>
                </AccordionTrigger>
                <AccordionContent className="p-3">
                  <NewInvoiceProductItems />
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="freight">
                <AccordionTrigger>
                  <div className="flex items-center gap-x-2">
                    <Truck className="size-5" />
                    <span>Transporte</span>
                  </div>
                </AccordionTrigger>
                <AccordionContent className="p-3">
                  <NewInvoiceProductFreight form={form} />
                </AccordionContent>
              </AccordionItem>

              <AccordionItem value="others">
                <AccordionTrigger>
                  <div className="flex items-center gap-x-2">
                    <ChatDots className="size-5 " />
                    <span>Outras Informações</span>
                  </div>
                </AccordionTrigger>
                <AccordionContent className="p-3">
                  <NewInvoiceProductOthers />
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </form>
        </Form>
      </PageContent>
    </PageContainer>
  );
}
