import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const getBase64 = (file: File) => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const result = reader.result as string;
      resolve(result.replace(/^data:.*?;base64,/, "").trim());
    };
    reader.onerror = (error) => reject(error);
  });
};

export const groupBy = <T, K extends keyof any>(
  array: T[],
  getKey: (item: T) => K
) =>
  array.reduce(
    (acc, current) => {
      const group = getKey(current);
      if (!acc[group]) acc[group] = [];
      acc[group].push(current);
      return acc;
    },
    {} as Record<K, T[]>
  );

export function isFalsy(value: any): value is false {
  if (Array.isArray(value)) {
    return value.length === 0;
  }

  return !value;
}

export type FlattenKeys<T, Prefix extends string = ""> = {
  [K in keyof T]: T[K] extends object
    ? FlattenKeys<T[K], `${Prefix}${K & string}.`>
    : `${Prefix}${K & string}`;
}[keyof T];
