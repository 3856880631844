import { FormFieldSimple } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { Button } from "@/components/ui/button.tsx";
import { SelectCityMenu } from "@/components/shared/select-city-menu.tsx";
import { SelectStateMenu } from "@/components/shared/select-state-menu.tsx";
import { useMultiStepFormContext } from "@/hooks/use-multistep.ts";
import { PatternInput } from "@/components/validators/pattern-input.tsx";

export function CreateVendorStep2() {
  const { form, nextStep } = useMultiStepFormContext();
  const stateCode = form.watch("address.stateCode");

  return (
    <div className="flex flex-col gap-4 h-full">
      <div className="flex flex-col gap-4 flex-wrap flex-1">
        <div className="flex gap-2 justify-between flex-wrap">
          <FormFieldSimple
            name="address.street"
            label="Logradouro"
            required={true}
            control={form.control}
            className="flex-1"
            render={({ field }) => <Input {...field} />}
          />
          <FormFieldSimple
            control={form.control}
            name="address.number"
            label="Número"
            required={true}
            className="w-24"
            render={({ field }) => <Input maxLength={10} {...field} />}
          />
        </div>
        <div className="flex gap-2 justify-between">
          <FormFieldSimple
            control={form.control}
            name="address.stateCode"
            label="Estado (UF)"
            required={true}
            className="w-2/3"
            render={({ field }) => (
              <SelectStateMenu
                value={field.value ?? undefined}
                onValueChange={(state) => {
                  field.onChange(state.code);
                  form.setValue("cityCode", "");
                }}
              />
            )}
          />
          <FormFieldSimple
            control={form.control}
            name="address.cityCode"
            label="Cidade de atuação"
            required={true}
            className="w-full"
            render={({ field }) => (
              <SelectCityMenu
                stateCode={stateCode}
                value={field.value}
                onValueChange={field.onChange}
              />
            )}
          />
        </div>
        <div className="flex gap-2 justify-between">
          <FormFieldSimple
            control={form.control}
            name="address.zipCode"
            label="CEP"
            required={true}
            className="w-full"
            render={({ field }) => (
              <PatternInput
                placeholder="CEP"
                {...field}
                onValueChange={(c) => field.onChange(c.value)}
                format="#####-###"
              />
            )}
          />
          <FormFieldSimple
            control={form.control}
            name="address.district"
            label="Bairro"
            required={true}
            className="w-full"
            render={({ field }) => <Input placeholder="Bairro" {...field} />}
          />
        </div>
        <FormFieldSimple
          control={form.control}
          name="address.complement"
          label="Complemento"
          required={false}
          className="w-full"
          render={({ field }) => (
            <Input
              {...field}
              placeholder="Complemento"
              value={field.value ?? ""}
            />
          )}
        />
      </div>
      <div className="ml-auto">
        <Button type="button" onClick={nextStep}>
          Próximo
        </Button>
      </div>
    </div>
  );
}
