import type { ComponentPropsWithoutRef } from "react";
import { cn } from "@/lib/utils.ts";
import { Slot } from "@radix-ui/react-slot";

type Props = ComponentPropsWithoutRef<"div"> & {
  asChild?: boolean;
};

export function Box({ className, children, asChild = false, ...props }: Props) {
  const Component = asChild ? Slot : "div";

  return (
    <Component className={cn("p-4 shadow-md rounded", className)} {...props}>
      {children}
    </Component>
  );
}

export function BoxTitle({
  children,
  className,
  ...props
}: ComponentPropsWithoutRef<"h2">) {
  return (
    <h2 className={cn("text-lg font-semibold", className)} {...props}>
      {children}
    </h2>
  );
}
