import { useMultiStepFormContext } from "@/hooks/use-multistep.ts";

import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { type ComponentPropsWithoutRef, useId } from "react";
import { Box, BoxTitle } from "@/components/tw-ui/box.tsx";
import { FormField, FormFieldSimple } from "@/components/ui/form.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Input } from "@/components/ui/input.tsx";
import { cn } from "@/lib/utils.ts";

function ServiceSwitch({
  name,
  label,
  description,
  icon = null,
  ...props
}: {
  name: string;
  label: string;
  description: string;
  icon?: React.ReactNode;
} & ComponentPropsWithoutRef<typeof Switch>) {
  const id = useId();
  const { form } = useMultiStepFormContext();

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => {
        return (
          <div className="relative flex w-full items-start gap-2 rounded-lg border border-input p-4 shadow-sm shadow-black/5 has-[[data-state=checked]]:border-primary transition">
            <Switch
              id={`switch-${id}`}
              checked={field.value}
              onCheckedChange={field.onChange}
              className="order-1 h-4 w-8 after:absolute after:inset-0 [&_span]:size-3 [&_span]:data-[state=checked]:translate-x-4 rtl:[&_span]:data-[state=checked]:-translate-x-4"
              {...props}
            />
            <div className="flex grow items-center gap-3">
              {icon}
              <div className="grid grow gap-2">
                <Label
                  htmlFor={`switch-${id}`}
                  className="text-sm font-semibold"
                >
                  {label}
                </Label>
                <p className="text-xs text-muted-foreground">{description}</p>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}

export function CreateCompanyStep4() {
  const { form, nextStep } = useMultiStepFormContext();

  const isSendProductInvoiceEnabled = form.watch("services.sendProductInvoice");

  const isSendServiceInvoiceEnabled = form.watch("services.sendServiceInvoice");

  return (
    <div className="flex flex-col gap-4 h-full">
      <Box className="flex flex-col gap-2 pt-0">
        <BoxTitle>Nota Fiscal de Serviço Eletrônica</BoxTitle>
        <div className="flex flex-col gap-4">
          <ServiceSwitch
            name="services.sendServiceInvoice"
            label="NFSe - Emissão"
            description="Habilite a emissão de NFSes, facilitando o registro e garantindo conformidade fiscal."
          />
          <div
            className={cn(
              "flex gap-2 overflow-hidden max-h-0 transition-all duration-200",
              isSendServiceInvoiceEnabled && "max-h-[100px]"
            )}
          >
            <FormFieldSimple
              name="services.sendServiceUsername"
              label="Usuário"
              required={false}
              className="w-1/2"
              render={({ field }) => <Input {...field} />}
            />
            <FormFieldSimple
              name="services.sendServicePassword"
              label="Senha"
              required={false}
              className="w-1/2"
              render={({ field }) => <Input {...field} />}
            />
          </div>
        </div>
      </Box>
      <Box className="flex flex-col gap-2 pt-0">
        <BoxTitle>Nota Fiscal Eletrônica</BoxTitle>
        <div className="flex flex-col gap-4">
          <ServiceSwitch
            name="services.receiveProductInvoice"
            label="NFe - Captura"
            description="Receba automaticamente NFes emitidas contra seu CNPJ, sem precisar entrar em contato com o fornecedor."
          />
          <div className="flex flex-col gap-4">
            <ServiceSwitch
              name="services.sendProductInvoice"
              label="NFe - Emissão"
              description="Utilize o Emissor NFe mais completo e rápido do Brasil para agilizar seu processo de emissão de notas fiscais."
            />
            <div
              className={cn(
                "flex gap-2 overflow-hidden max-h-0 transition-all duration-200",
                isSendProductInvoiceEnabled && "max-h-[100px]"
              )}
            >
              <FormFieldSimple
                name="services.sendProductInvoiceSeries"
                label="Série NFe"
                required={true}
                render={({ field }) => (
                  <Input {...field} type="number" min={0} />
                )}
              />
              <FormFieldSimple
                name="services.sendProductInvoiceNextNumber"
                label="Próximo número"
                required={true}
                render={({ field }) => (
                  <Input {...field} type="number" min={0} />
                )}
              />
            </div>
          </div>
        </div>
      </Box>
      <div className="flex-1 flex justify-end items-end">
        <Button type="button" onClick={nextStep}>
          Próximo
        </Button>
      </div>
    </div>
  );
}
