import { Label } from "@/components/ui/label.tsx";
import { useEffect, useState, useCallback } from "react";
import {
  CurrencyInput,
  PercentageInput,
} from "@/components/validators/currency-input.tsx";

interface ComponentsProps {
  baseCalculation: string;
  label: string;
  onChange: (value: string) => void;
}

export function BasePercentageCalculator({
  baseCalculation: initialBaseCalculation,
  label,
  onChange,
}: ComponentsProps) {
  const [baseCalculationValue, setBaseCalculationValue] = useState(
    initialBaseCalculation
  );
  const [percentageValue, setPercentageValue] = useState("0");
  const [resultValue, setResultValue] = useState("0");

  useEffect(() => {
    setBaseCalculationValue(initialBaseCalculation);
  }, [initialBaseCalculation]);

  const calculateResult = useCallback(() => {
    const percentage = Number(percentageValue);
    const baseValue = Number(baseCalculationValue);

    if (isNaN(percentage) || isNaN(baseValue)) {
      const defaultResult = "0";
      setResultValue(defaultResult);
      onChange(defaultResult);
      return;
    }

    const result = ((percentage / 100) * baseValue).toFixed(2);
    setResultValue(result);
    onChange(result);
  }, [baseCalculationValue, percentageValue, onChange]);

  useEffect(() => {
    calculateResult();
  }, [calculateResult]);

  const handlePercentageChange = useCallback((value: string) => {
    setPercentageValue(value);
  }, []);

  const handleBaseCalculationChange = useCallback((value: string) => {
    setBaseCalculationValue(value);
  }, []);

  return (
    <div className="flex flex-col">
      <h3 className="font-medium text-md">{label}</h3>
      <div className="flex items-start flex-col 2xl:flex-row 2xl:items-center 2xl:gap-x-2">
        <div>
          <Label htmlFor="base" className="text-xs">
            Base de Cálculo
          </Label>
          <CurrencyInput
            id="base"
            value={baseCalculationValue}
            onChange={handleBaseCalculationChange}
          />
        </div>
        <div>
          <Label htmlFor="percentage" className="text-xs">
            Alíquota
          </Label>
          <PercentageInput
            id="percentage"
            value={percentageValue}
            onChange={handlePercentageChange}
          />
        </div>
        <div>
          <Label htmlFor="result" className="text-xs">
            Valor
          </Label>
          <CurrencyInput
            id="result"
            value={resultValue}
            onChange={() => {}}
            disabled
          />
        </div>
      </div>
    </div>
  );
}
