import { useFieldArray, useFormContext } from "react-hook-form";
import { type NewProductInvoice } from "@shared/schemas/invoices/nfe/new_product.ts";
import { Button } from "@/components/ui/button.tsx";
import { Package, PencilSimple, TrashSimple } from "@phosphor-icons/react";
import { PlusCircle } from "lucide-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table.tsx";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import { formatCurrency } from "@/lib/currency.ts";
import { CFOP } from "@shared/constants/cfop.ts";
import { pushModal } from "@/modals";

type ProductItem = NewProductInvoice["items"][0];

export function NewInvoiceProductItems() {
  const form = useFormContext<NewProductInvoice>();

  const fieldArray = useFieldArray({
    control: form.control,
    name: "items",
  });

  return (
    <div className="flex flex-col gap-2">
      <Button
        type="button"
        variant="outline"
        className="w-fit gap-2"
        onClick={() =>
          pushModal("NewProductInvoiceAddItemModal", {
            onCreate: (item) => fieldArray.append(item),
          })
        }
      >
        <div className="relative">
          <Package className="size-5" />
          <PlusCircle className="size-3 bottom-0 -right-1 absolute bg-white rounded-full" />
        </div>
        <span>Adicionar produto</span>
      </Button>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[100px]">Código</TableHead>
            <TableHead className="w-[15px]">CFOP</TableHead>
            <TableHead className="w-[400px]">Descrição</TableHead>
            <TableHead>Quantidade</TableHead>
            <TableHead>Valor Unitário</TableHead>
            <TableHead>Valor Total</TableHead>
            <TableHead />
          </TableRow>
        </TableHeader>
        <TableBody>
          {fieldArray.fields.length === 0 && (
            <TableRow>
              <TableCell colSpan={7}>
                <div className="flex items-center justify-center p-1 text-center text-gray-500">
                  <span>Nenhum produto adicionado.</span>
                </div>
              </TableCell>
            </TableRow>
          )}
          {fieldArray.fields.map((item, index) => {
            const commercialQuantity = Number(item.commercialQuantity ?? 0);
            const commercialUnitValue = Number(item.commercialUnitValue ?? 0);
            const totalValue = commercialQuantity * commercialUnitValue;

            const formattedTotalValue = formatCurrency(totalValue, "BRL");

            return (
              <TableRow key={item.id}>
                <TableCell>{item.code}</TableCell>
                <TableCell>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <span className="hover:cursor-help">{item.cfop}</span>
                    </TooltipTrigger>
                    <TooltipContent className="max-w-[20em] text-xs">
                      {CFOP.get(item.cfop)?.label ?? "CFOP desconhecido"}
                    </TooltipContent>
                  </Tooltip>
                </TableCell>
                <TableCell>{item.description}</TableCell>
                <TableCell>{item.commercialQuantity}</TableCell>
                <TableCell>
                  {formatCurrency(item.commercialUnitValue, "BRL")}
                </TableCell>
                <TableCell>{formattedTotalValue}</TableCell>
                <TableCell className="flex justify-end">
                  <ProductItemActions
                    index={index}
                    item={item}
                    description={item.description}
                    onRemove={(index) => fieldArray.remove(index)}
                    onUpdate={(index, item) => fieldArray.update(index, item)}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

function ProductItemActions({
  description,
  item,
  index,
  onRemove,
  onUpdate,
}: {
  description: string;
  item: ProductItem;
  index: number;
  onRemove: (index: number) => void;
  onUpdate: (index: number, item: ProductItem) => void;
}) {
  return (
    <div className="flex gap-2">
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="outline"
            size="xs"
            type="button"
            onClick={() =>
              pushModal("NewProductInvoiceAddItemModal", {
                item,
                index,
                onUpdate,
              })
            }
          >
            <PencilSimple className="size-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <span>Editar produto</span>
        </TooltipContent>
      </Tooltip>

      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="destructive"
            size="xs"
            type="button"
            onClick={() =>
              pushModal("ConfirmationModal", {
                title: `Remover produto: ${description}`,
                confirmation: () => {
                  onRemove(index);
                },
              })
            }
          >
            <TrashSimple className="size-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <span>Remover produto</span>
        </TooltipContent>
      </Tooltip>
    </div>
  );
}
