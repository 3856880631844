import { useEffect } from "react";
import { create } from "zustand";

export const useTitleStore = create<{
  title: string;
  setTitle: (title: string) => void;
}>((set) => ({
  title: "",
  setTitle: (title: string) => {
    document.title = `TW Docs - ${title}`;
    set({ title });
  },
}));

export function useTitle(title: string) {
  const { setTitle } = useTitleStore();

  useEffect(() => {
    setTitle(title);
  }, [setTitle, title]);

  return useTitleStore((state) => state);
}
