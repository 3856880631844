import { FormFieldSimple, FormControl } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";

import { Button } from "@/components/ui/button.tsx";
import { useMultiStepFormContext } from "@/hooks/use-multistep";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";
import { RegimeTributary } from "@shared/schemas/company.ts";
import { Box } from "@/components/tw-ui/box.tsx";

export function CreateCompanyStep5() {
  const { form, nextStep } = useMultiStepFormContext();

  return (
    <div className="flex flex-col gap-4 h-full">
      <Box className="flex flex-col gap-4 flex-wrap">
        <FormFieldSimple
          name="tributes.tributaryRegime"
          label="Regime Tributário"
          description="Selecione o regime tributário da empresa"
          required={true}
          render={({ field }) => (
            <Select
              onValueChange={(value) => field.onChange(Number.parseInt(value))}
              defaultValue={field.value.toString()}
            >
              <FormControl>
                <SelectTrigger>
                  <SelectValue placeholder="Selecione o regime tributário" />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                <SelectItem value={RegimeTributary.REGIME_NORMAL.toString()}>
                  Regime Normal
                </SelectItem>
                <SelectItem value={RegimeTributary.SIMPLES_NACIONAL.toString()}>
                  Simples Nacional
                </SelectItem>
                <SelectItem
                  value={RegimeTributary.SIMPLES_NACIONAL_EXCESSO.toString()}
                >
                  Simples Nacional - Excesso de Receita Bruta
                </SelectItem>
              </SelectContent>
            </Select>
          )}
        />
        <div className="flex flex-row flex-wrap lg:flex-nowrap gap-2">
          <FormFieldSimple
            name="tributes.municipalRegistration"
            label="Inscrição Municipal"
            description="Inscrição obrigatória para emissão de NFSe."
            required={true}
            control={form.control}
            className="w-full"
            render={({ field }) => (
              <Input placeholder="Inscrição Municipal" {...field} />
            )}
          />
          <FormFieldSimple
            name="tributes.stateRegistration"
            label="Inscrição Estadual"
            description="Inscrição obrigatória para emissão/captura de NFe."
            required={true}
            control={form.control}
            className="w-full"
            render={({ field }) => (
              <Input placeholder="Inscrição Estadual" {...field} />
            )}
          />
        </div>
      </Box>
      <div className="ml-auto">
        <Button type="button" onClick={nextStep}>
          Próximo
        </Button>
      </div>
    </div>
  );
}
