import type { ColumnDef } from "@tanstack/react-table";
import { DataTableColumnHeader } from "@/components/table/data-table-column-header.tsx";
import { format } from "date-fns";
import { formatCurrency } from "@/lib/currency.ts";
import type { EvaluationList } from "@shared/types/evaluation.ts";
import { evaluationStatus } from "@/pages/evaluation/util.ts";
import { Button } from "@/components/ui/button.tsx";
import { Eye } from "@phosphor-icons/react";
import { Link } from "react-router-dom";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";

export const documentListColumns: ColumnDef<EvaluationList>[] = [
  {
    accessorKey: "createdAt",
    header: ({ column }) => (
      <DataTableColumnHeader title="Data de criação" column={column} />
    ),
    cell: ({ row }) => {
      return <div>{format(row.original.createdAt, "dd/MM/yyyy")}</div>;
    },
  },

  {
    accessorKey: "company.name",
    header: ({ column }) => (
      <DataTableColumnHeader title="Empresa" column={column} />
    ),
    cell: ({ row }) => {
      return <div>{row.original.company.name}</div>;
    },
  },
  {
    accessorKey: "vendor.name",
    header: ({ column }) => (
      <DataTableColumnHeader title="Fornecedor" column={column} />
    ),
    cell: ({ row }) => {
      return <div>{row.original.vendor.name}</div>;
    },
  },
  {
    accessorKey: "invoice.number",
    header: ({ column }) => (
      <DataTableColumnHeader title="Número NF" column={column} />
    ),
    cell: ({ row }) => {
      return <div>{row.original.invoice.number}</div>;
    },
  },
  {
    accessorKey: "invoice.issuedAt",
    header: ({ column }) => (
      <DataTableColumnHeader title="Data de emissão NF" column={column} />
    ),
    cell: ({ row }) => {
      return <div>{format(row.original.invoice.issuedAt, "dd/MM/yyyy")}</div>;
    },
  },
  {
    accessorKey: "value",
    header: ({ column }) => (
      <DataTableColumnHeader title="Valor" column={column} />
    ),
    cell: ({ row }) => {
      return <div>{formatCurrency(row.original.value, "BRL")}</div>;
    },
  },
  {
    accessorKey: "step",
    header: ({ column }) => (
      <DataTableColumnHeader title="Etapa" column={column} />
    ),
    cell: ({ row }) => {
      return (
        <div>
          {row.original.completed
            ? "Finalizado"
            : evaluationStatus[row.original.step]}
        </div>
      );
    },
  },
  {
    accessorKey: "updatedAt",
    header: ({ column }) => (
      <DataTableColumnHeader title="Data de atualização" column={column} />
    ),
    cell: ({ row }) => {
      return <div>{format(row.original.updatedAt, "dd/MM/yyyy")}</div>;
    },
  },
  {
    id: "actions",

    cell: ({ row }) => {
      return (
        <div className="flex items-center gap-2">
          <Tooltip>
            <TooltipTrigger>
              <Link to={`/evaluation/document/${row.original.id}`}>
                <Button variant="outline" size="xs">
                  <Eye className="size-5" />
                </Button>
              </Link>
            </TooltipTrigger>
            <TooltipContent>
              <p className="text-xs">Visualizar documento</p>
            </TooltipContent>
          </Tooltip>
        </div>
      );
    },
  },
];
