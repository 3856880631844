import {
  PageContainer,
  PageContent,
  PageDescription,
  PageHeader,
  PageTitle,
} from "@/components/layout/page.tsx";
import {
  CardButton,
  CardButtonContent,
  CardButtonDescription,
  CardButtonIcon,
} from "@/components/card-button.tsx";
import { FilePlus, Pencil } from "@phosphor-icons/react";
import { DataTable, useGenericTable } from "@/components/table/data-table.tsx";
import { DataTablePagination } from "@/components/table/data-table-pagination.tsx";
import type { ColumnDef, Row } from "@tanstack/react-table";
import { DataTableColumnHeader } from "@/components/table/data-table-column-header.tsx";
import { Badge } from "@/components/ui/badge.tsx";
import { format } from "date-fns";
import { formatCurrency } from "@/lib/currency.ts";
import { Button } from "@/components/ui/button.tsx";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import type { FC } from "react";
import { Link } from "react-router-dom";

type MOCK_INVOICE_TYPE = {
  id: string;
  invoiceType: "sent" | "received";
  company: {
    document: string;
    name: string;
  };
  vendor: {
    document: string;
    name: string;
  };
  status: "draft" | "processing" | "success" | "cancelled" | "error";
  value: string;
  createdAt: string;
};

const StatusBadge: FC<{ status: MOCK_INVOICE_TYPE["status"] }> = ({
  status,
}) => {
  const colorMap: Record<MOCK_INVOICE_TYPE["status"], string> = {
    draft: "bg-gray-500 hover:bg-gray-400",
    processing: "bg-blue-500 hover:bg-blue-400",
    success: "bg-green-500 hover:bg-green-400",
    cancelled: "bg-yellow-500 hover:bg-yellow-400",
    error: "bg-red-500 hover:bg-red-400",
  };
  const statusMap: Record<MOCK_INVOICE_TYPE["status"], string> = {
    draft: "Em digitação",
    processing: "Em processamento",
    success: "Autorizada",
    cancelled: "Cancelado",
    error: "Erro",
  };
  return (
    <Badge className={`${colorMap[status]} text-white`}>
      {statusMap[status]}
    </Badge>
  );
};

const columns: ColumnDef<MOCK_INVOICE_TYPE>[] = [
  {
    accessorKey: "createdAt",
    header: ({ column }) => (
      <DataTableColumnHeader title="Data" column={column} />
    ),
    cell: ({ row }) => {
      return <span>{format(row.original.createdAt, "dd/MM/yyyy")}</span>;
    },
  },
  {
    accessorKey: "company.name",
    header: ({ column }) => (
      <DataTableColumnHeader title="Emitente" column={column} />
    ),
    cell: ({ row }) => {
      return <span>{row.original.company.name}</span>;
    },
  },
  {
    accessorKey: "vendor.name",
    header: ({ column }) => (
      <DataTableColumnHeader title="Destinatário" column={column} />
    ),
    cell: ({ row }) => {
      return <span>{row.original.vendor.name}</span>;
    },
  },
  {
    accessorKey: "status",
    header: ({ column }) => (
      <DataTableColumnHeader title="Status" column={column} />
    ),
    cell: ({ row }) => {
      return <StatusBadge status={row.original.status} />;
    },
  },
  {
    accessorKey: "value",
    header: ({ column }) => (
      <DataTableColumnHeader title="Valor" column={column} />
    ),
    cell: ({ row }) => {
      return <span>{formatCurrency(row.original.value, "BRL")}</span>;
    },
  },
  {
    id: "actions",
    cell: ({ row }) => ProductTableActions(row),
  },
];

function ProductTableActions(row: Row<MOCK_INVOICE_TYPE>) {
  return (
    <div className="flex justify-center gap-2">
      <Tooltip delayDuration={100}>
        <TooltipTrigger asChild>
          <Link to={`/invoices/product/sent/new?invoiceId=${row.original.id}`}>
            <Button variant="outline" size="xs">
              <Pencil className="h-4 w-4" />
            </Button>
          </Link>
        </TooltipTrigger>
        <TooltipContent className="text-xs">Editar</TooltipContent>
      </Tooltip>
    </div>
  );
}

export function ManageProductInvoices() {
  const table = useGenericTable({
    columns: columns,
    data: [],
  });

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Nota Fiscal de Produto (NF-e)</PageTitle>
        <PageDescription>
          Visualize as notas pendentes para a emissão, crie uma nova ou gerencie
          os produtos.
        </PageDescription>
      </PageHeader>
      <PageContent>
        <div className="flex items-center gap-3">
          <CardButton navigateTo={"/invoices/product/sent/new"} disabled>
            <CardButtonContent>
              <CardButtonIcon>
                <FilePlus />
              </CardButtonIcon>
              <CardButtonDescription>Criar nova NF-e</CardButtonDescription>
            </CardButtonContent>
          </CardButton>
          {/*<CardButton>*/}
          {/*  <CardButtonContent>*/}
          {/*    <CardButtonIcon>*/}
          {/*      <Package />*/}
          {/*    </CardButtonIcon>*/}
          {/*    <CardButtonDescription>Gerenciar produtos</CardButtonDescription>*/}
          {/*  </CardButtonContent>*/}
          {/*</CardButton>*/}
        </div>
        <div className="space-y-4 mt-4">
          <DataTable table={table} />
          <DataTablePagination table={table} />
        </div>
      </PageContent>
    </PageContainer>
  );
}
