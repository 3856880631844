import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog.tsx";
import { useQuery } from "@tanstack/react-query";
import { FileX } from "lucide-react";
import { format } from "date-fns";

interface Props {
  id: string;
}

export default function ViewSentProductInvoice({ id }: Props) {
  const { data } = useQuery<any>({
    queryKey: [`/invoices/sent/products/${id}`],
    enabled: !!id,
  });

  if (!data) {
    return null;
  }

  const issuedAt = data.issuedAt
    ? format(new Date(data.issuedAt), "dd/MM/yyyy")
    : "";
  const invoiceType = data.invoiceType === "sale" ? "SAÍDA" : "ENTRADA";
  const isPdfAvailable = !!data.links.pdf;

  return (
    <>
      <DialogContent
        onOpenAutoFocus={(e) => e.preventDefault()}
        className={"max-w-5xl max-h-[95vh] overflow-y-auto"}
      >
        <DialogHeader className={"font-medium text-lg"}>
          <DialogTitle>Visualizando Nota Fiscal emitida (NF-e)</DialogTitle>
          <DialogDescription>
            <p className={"text-xs"}>Chave de acesso: {data.externalId}</p>
            <div className={"flex justify-between items-center"}>
              {issuedAt && (
                <p className={"text-xs"}>Data de emissão: {issuedAt}</p>
              )}
              <p className={"text-xs"}>
                Tipo: {invoiceType}, Número da Nota:{" "}
                {data.details.invoiceNumber}, Série:{" "}
                {data.details.invoiceSeries}
              </p>
            </div>
          </DialogDescription>
        </DialogHeader>
        <div
          className={`flex flex-col ${isPdfAvailable ? "flex-grow" : ""} overflow-hidden space-y-4`}
        >
          {isPdfAvailable ? (
            <div className="flex-grow relative">
              <embed
                src={`${data.links.pdf}#zoom=85`}
                className="absolute inset-0 w-full h-full"
                title="pdf"
                type="application/pdf"
              />
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center p-8 text-center">
              <FileX className="w-16 h-16 text-gray-400 mb-4" />
              <p className="text-lg font-medium text-gray-700">
                Esta nota ainda não foi processada em nosso sistema, tente
                novamente mais tarde.
              </p>
            </div>
          )}
        </div>
      </DialogContent>
    </>
  );
}
