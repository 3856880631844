import { Form } from "@/components/ui/form.tsx";
import { useForm } from "react-hook-form";
import type { z } from "zod";
import {
  serviceInvoiceSchema,
  ServiceLocationType,
} from "@shared/schemas/invoices/service.ts";
import { zodResolver } from "@hookform/resolvers/zod";

import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchApi } from "@/lib/api.ts";
import { useNavigate } from "react-router-dom";
import { PendingButton } from "@/components/pending-button.tsx";
import { ServiceBaseForm } from "@/pages/invoices/service/components/service-base-form.tsx";
import { RecipientForm } from "@/pages/invoices/service/components/recipient-form.tsx";
import { ServiceForm } from "@/pages/invoices/service/components/service-form.tsx";
import { TaxForm } from "@/pages/invoices/service/components/tax-form.tsx";
import type { CompanyWithAddress } from "@shared/schemas/company.ts";
import {
  PageContainer,
  PageContent,
  PageDescription,
  PageHeader,
  PageTitle,
} from "@/components/layout/page.tsx";
import { ServiceAddressForm } from "@/pages/invoices/service/components/service-address-form.tsx";

type CreateNfse = z.infer<typeof serviceInvoiceSchema>;

export function CreateServiceInvoice() {
  const form = useForm<CreateNfse>({
    resolver: zodResolver(serviceInvoiceSchema),
    defaultValues: {
      issuedAt: new Date().toISOString(),
      service: {
        addressLocation: ServiceLocationType.ISSUER,
      },
    },
  });

  const navigate = useNavigate();

  const { data } = useQuery<{
    companies: CompanyWithAddress[];
  }>({
    queryKey: ["/companies"],
  });

  const { mutate, isPending } = useMutation({
    mutationKey: [`/invoices/sent/services`],
    mutationFn: (nfse: CreateNfse) => {
      return fetchApi(`/invoices/sent/services`, {
        method: "POST",
        data: nfse,
      });
    },
    onSuccess: () => {
      navigate("/invoices/service/sent");
    },
  });

  const submit = form.handleSubmit((data) => {
    mutate(data);
  });

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Emitir Nota Fiscal de Serviços Eletrônica</PageTitle>
        <PageDescription>
          Preencha os dados para emitir uma nota fiscal de serviços eletrônica.
        </PageDescription>
      </PageHeader>
      <PageContent>
        <Form {...form}>
          <form onSubmit={submit} className="flex flex-col gap-y-3">
            <ServiceBaseForm companies={data?.companies} />
            <RecipientForm />
            <ServiceForm companies={data?.companies} />
            <ServiceAddressForm />
            <TaxForm />
            <div className="flex justify-end">
              <PendingButton isPending={isPending} type="submit">
                Emitir
              </PendingButton>
            </div>
          </form>
        </Form>
      </PageContent>
    </PageContainer>
  );
}
