import { Button } from "@/components/ui/button.tsx";
import type { ComponentProps } from "react";
import { Loader2 } from "lucide-react";

interface Props extends ComponentProps<typeof Button> {
  /**
   * @deprecated since version 2.0
   */
  pending?: boolean;

  isPending?: boolean;
}

export function PendingButton({
  children,
  isPending = false,
  pending = false,
  ...props
}: Props) {
  return (
    <Button {...props} disabled={props.disabled || isPending || pending}>
      {(isPending || pending) && <Loader2 className="mr-2 animate-spin" />}
      {typeof children === "string" ? <span>{children}</span> : children}
    </Button>
  );
}
