import type { ColumnDef } from "@tanstack/react-table";

import { DataTableColumnHeader } from "@/components/table/data-table-column-header.tsx";
import { DataTableRowActions } from "@/components/table/data-table-row-actions.tsx";

// TODO: ADD ICONS
const typeMap = {
  financial: "Financeiro",
  legal: "Contabilidade",
  sector: "Setor de Aprovação",
};

interface GetGroupTableColumns {
  onRequestUpdate: (groupId: string) => void;
  onRequestDelete: (groupId: string) => void;
}

export function getGroupTableColumns({
  onRequestDelete,
  onRequestUpdate,
}: GetGroupTableColumns): ColumnDef<{
  id: string;
  name: string;
  admins: {
    id: string;
    name: string;
  }[];
  type: "legal" | "sector" | "financial";
}>[] {
  return [
    {
      accessorKey: "name",
      enableSorting: true,
      header: ({ column }) => (
        <DataTableColumnHeader title="Nome do grupo" column={column} />
      ),
      cell: ({ row }) => <div>{row.getValue("name")}</div>,
    },
    {
      accessorKey: "type",
      enableSorting: true,
      header: ({ column }) => (
        <DataTableColumnHeader title="Categoria" column={column} />
      ),
      cell: ({ row }) => {
        return <div>{typeMap[row.original.type]}</div>;
      },
    },
    {
      accessorKey: "admins",
      enableHiding: false,
      enableSorting: false,
      header: ({ column }) => (
        <DataTableColumnHeader title="Responsáveis" column={column} />
      ),
      cell: ({ row }) => {
        const admins = row.original.admins;

        return (
          <div className="flex space-x-2 min-w-[80px]">
            <span className="font-medium">
              {admins.map((admin) => admin.name).join(", ")}
            </span>
          </div>
        );
      },
    },
    {
      id: "actions",
      cell: ({ row }) => {
        return (
          <DataTableRowActions
            row={row}
            options={[
              {
                label: "Editar",
                onClick: () => onRequestUpdate(row.original.id),
              },
              {
                label: "Excluir",
                onClick: () => onRequestDelete(row.original.id),
              },
            ]}
          />
        );
      },
    },
  ];
}
