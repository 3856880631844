import {
  PageContainer,
  PageContent,
  PageDescription,
  PageHeader,
  PageTitle,
} from "@/components/layout/page.tsx";

export function EvaluationSettings() {
  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Configurações da Matriz de Avaliação</PageTitle>
        <PageDescription>
          Defina as configurações para a Matriz de Avaliação
        </PageDescription>
      </PageHeader>
      <PageContent>
        <span>Hello world!</span>
      </PageContent>
    </PageContainer>
  );
}
