import {
  Form,
  FormControl,
  FormField,
  FormFieldSimple,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form.tsx";
import { inputVendorSchema } from "@shared/schemas/vendor.ts";
import { useForm } from "react-hook-form";
import type { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useParams } from "react-router-dom";
import { useVendor } from "@/hooks/use-vendor.ts";
import { Input } from "@/components/ui/input.tsx";
import { fetchApi } from "@/lib/api.ts";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";

import { PendingButton } from "@/components/pending-button.tsx";
import { SelectCityMenu } from "@/components/shared/select-city-menu.tsx";
import { SelectStateMenu } from "@/components/shared/select-state-menu.tsx";

export function EditVendor() {
  const { document } = useParams();
  const { data, isError } = useVendor(document);
  const queryClient = useQueryClient();

  const form = useForm<z.infer<typeof inputVendorSchema>>({
    resolver: zodResolver(inputVendorSchema),
    values: data,
  });

  const { mutate, isPending } = useMutation({
    mutationKey: [`/vendors/${document}`],
    mutationFn: (data: z.infer<typeof inputVendorSchema>) =>
      fetchApi.put(`/vendors/${document}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [`/vendors/${document}`],
      });
      toast.success("Dados atualizados com sucesso!");
    },
  });

  const stateCode = form.watch("address.stateCode");

  if (isError || !data) {
    return (
      <div className="flex justify-center items-center h-64">
        <p>Erro ao carregar os dados</p>
      </div>
    );
  }

  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit((val) => mutate(val))}>
          <div className={"flex items-center justify-between"}>
            <p className="text-lg font-semibold">Dados Cadastrais</p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 mt-6 gap-y-5 gap-x-3">
            <FormField
              name="name"
              render={({ field }) => (
                <FormItem className="col-span-2">
                  <FormLabel>Razão Social</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="fantasyName"
              render={({ field }) => (
                <FormItem className="col-span-2">
                  <FormLabel>Nome Fantasia</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              name="stateRegistration"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Inscrição Estadual</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="municipalRegistration"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Inscrição Municipal</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <p className="font-semibold text-lg my-6">Informações de Contato</p>
          <div className="grid grid-cols-1 md:grid-cols-2 mt-6 gap-y-5 gap-x-3">
            <FormField
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="phone"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Telefone</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <p className="font-semibold text-lg my-6">Endereço</p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-y-5 gap-x-3">
            <FormField
              name="address.street"
              render={({ field }) => (
                <FormItem className="col-span-2">
                  <FormLabel>Logradouro</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="address.number"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Número</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="address.district"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Bairro</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormFieldSimple
              name="address.stateCode"
              label="Estado"
              required={true}
              render={({ field }) => (
                <SelectStateMenu
                  value={field.value}
                  onValueChange={field.onChange}
                />
              )}
            />
            <FormFieldSimple
              name="address.cityCode"
              label="Município"
              required={true}
              render={({ field }) => (
                <SelectCityMenu
                  stateCode={stateCode}
                  value={field.value}
                  onValueChange={field.onChange}
                />
              )}
            />
            <FormField
              name="address.zipCode"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>CEP</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className={"mt-12 flex justify-end"}>
            <PendingButton isPending={isPending}>
              {isPending ? "Salvando..." : "Salvar alterações"}
            </PendingButton>
          </div>
        </form>
      </Form>
    </div>
  );
}
