import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import type { serviceInvoiceSchema } from "@shared/schemas/invoices/service.ts";
import { useFormContext, useWatch } from "react-hook-form";
import type { z } from "zod";
import { FormControl, FormField, FormItem } from "@/components/ui/form.tsx";
import { BasePercentageCalculator } from "@/components/base-percentage-calculator.tsx";

export function TaxForm() {
  const form = useFormContext<z.infer<typeof serviceInvoiceSchema>>();

  const [baseValue, serviceValue] = useWatch({
    control: form.control,
    name: ["service.baseValue", "service.value"],
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle>Tributos</CardTitle>
      </CardHeader>
      <CardContent>
        <div className={"flex flex-wrap gap-x-10 gap-y-5"}>
          <FormField
            control={form.control}
            name="service.pis"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <BasePercentageCalculator
                    baseCalculation={baseValue || serviceValue}
                    label="Retenção de PIS"
                    onChange={field.onChange}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="service.cofins"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <BasePercentageCalculator
                    baseCalculation={baseValue || serviceValue}
                    label="Retenção de COFINS"
                    onChange={field.onChange}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="service.inss"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <BasePercentageCalculator
                    baseCalculation={baseValue || serviceValue}
                    label="Retenção de INSS"
                    onChange={field.onChange}
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="service.ir"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <BasePercentageCalculator
                    baseCalculation={baseValue || serviceValue}
                    label="Retenção de IR"
                    onChange={field.onChange}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="service.csll"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <BasePercentageCalculator
                    baseCalculation={baseValue || serviceValue}
                    label="Retenção de CSLL"
                    onChange={field.onChange}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </div>
      </CardContent>
    </Card>
  );
}
