"use client";

import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";

import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { ScrollArea } from "./ui/scroll-area";
import { Bell, BellZ, CaretDown, CaretUp } from "@phosphor-icons/react";
import { useEffect, useRef, useState } from "react";
import {
  keepPreviousData,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { fetchApi, getApiData } from "@/lib/api";
import { toast } from "sonner";
import { format } from "date-fns";

interface Notification {
  id: string;
  title: string;
  description: string;
  readAt?: string;
  createdAt: string;
}

export function NotificationNav() {
  const [isOpen, setIsOpen] = useState(false);
  const [isSilenced, setIsSilenced] = useState(false);
  const [expandedNotifications, setExpandedNotifications] = useState<string[]>(
    []
  );
  const previousCount = useRef(0);

  const { data: notifications } = useQuery({
    queryKey: ["retrieve-all-notifications"],
    queryFn: () =>
      fetchApi
        .get("/notifications")
        .then(getApiData<{ notifications: Notification[] }>)
        .then((r) => r.notifications),
    refetchInterval: 1000 * 5 * 60,
    initialData: [],
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: true,
  });

  const queryClient = useQueryClient();

  const { mutate: markAsRead } = useMutation({
    mutationKey: ["/notifications/mark-as-read"],
    mutationFn: (ids: string[]) =>
      fetchApi.post("/notifications/mark-as-read", {
        ids,
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["retrieve-all-notifications"],
      });
    },
    onError: () => {},
  });

  useEffect(() => {
    if (!isSilenced && notifications.length > previousCount.current) {
      toast.info("Você tem novas notificações.");
    }

    previousCount.current = notifications.length;
  }, [notifications, isSilenced]);

  const unreadNotifications = notifications.filter((n) => !n.readAt);

  const toggleNotification = (id: string) => {
    setExpandedNotifications((prev) =>
      prev.includes(id) ? prev.filter((nId) => nId !== id) : [...prev, id]
    );
  };

  return (
    <Popover
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open);

        if (!open) {
          markAsRead(unreadNotifications.map((n) => n.id));
        }
      }}
    >
      <PopoverTrigger asChild={true}>
        <div
          className={cn(
            "flex items-center justify-between hover:bg-secondary/5 px-1 py-1.5 h-8 rounded-md select-none hover:cursor-pointer transition-colors duration-200 group/notification",
            unreadNotifications.length > 0 && "bg-secondary/10"
          )}
        >
          <div className="flex items-center gap-1">
            <Bell className="size-5" />
            <span className="text-sm">Notificações</span>
          </div>
          {unreadNotifications.length > 0 && (
            <Badge
              variant="secondary"
              border="dashed"
              className="group-hover/notification:bg-slate-200 group-hover/notification:border-slate-500/80"
            >
              {unreadNotifications.length}
            </Badge>
          )}
        </div>
      </PopoverTrigger>
      <PopoverContent
        side="right"
        className="flex flex-col gap-2 w-[30rem] rounded-md bg-white p-2 shadow-lg"
      >
        <div className="flex items-center justify-between w-full">
          <span className="text-sm">
            {isSilenced ? "Modo silencioso ativado" : "Suas notificações"}
          </span>
          <Button
            variant={isSilenced ? "pop" : "outline"}
            onClick={() => setIsSilenced((c) => !c)}
            size="icon"
            className="size-8 transition-all duration-200 ease-in-out"
          >
            <BellZ className="size-5" />
          </Button>
        </div>
        <ScrollArea className="h-96 w-full">
          <div className="flex flex-col gap-2">
            {notifications.map((notification) => (
              <Card
                key={notification.id}
                className="hover:bg-secondary/5 cursor-pointer"
                onClick={() => toggleNotification(notification.id)}
              >
                <CardHeader className="flex flex-row items-center justify-between p-2 space-y-0">
                  <CardTitle className="text-xs">
                    <span className="text-muted-foreground">
                      {notification.title}
                    </span>
                  </CardTitle>
                  <div className="flex items-center gap-2">
                    <span className="text-xs">
                      {format(new Date(notification.createdAt), "dd/MM/yyyy")}
                    </span>
                    {expandedNotifications.includes(notification.id) ? (
                      <CaretUp className="size-4" />
                    ) : (
                      <CaretDown className="size-4" />
                    )}
                  </div>
                </CardHeader>
                {expandedNotifications.includes(notification.id) && (
                  <CardContent className="text-xs p-2">
                    <p>{notification.description}</p>
                  </CardContent>
                )}
              </Card>
            ))}
          </div>
        </ScrollArea>
      </PopoverContent>
    </Popover>
  );
}
