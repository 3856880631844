import {
  PageContainer,
  PageContent,
  PageDescription,
  PageHeader,
  PageTitle,
} from "@/components/layout/page.tsx";
import {
  CardButton,
  CardButtonContent,
  CardButtonDescription,
  CardButtonIcon,
} from "@/components/card-button.tsx";
import { pushModal } from "@/modals";
import { Building } from "@phosphor-icons/react";
import { useGenericTable } from "@/components/table/data-table.tsx";
import {
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { fetchApi, getApiData } from "@/lib/api.ts";
import { getGroupTableColumns } from "@/pages/groups/table/group-table-columns.tsx";
import { DataTable } from "@/components/data-table/data-table.tsx";
import { DataTableFacetedFilter } from "@/components/data-table/data-table-faceted-filter.tsx";
import { DataTableViewOptions } from "@/components/data-table/data-table-view-options.tsx";
import { DataTablePagination } from "@/components/data-table/data-table-pagination.tsx";

export function Groups() {
  const { data: groups } = useSuspenseQuery({
    queryFn: () =>
      fetchApi
        .get("/groups")
        .then(
          getApiData<{
            groups: Array<{
              id: string;
              name: string;
              admins: Array<{
                id: string;
                name: string;
              }>;
              type: "legal" | "sector" | "financial";
            }>;
          }>
        )
        .then((res) => res.groups),
    queryKey: ["groups"],
  });

  const queryClient = useQueryClient();

  const { mutate: deleteGroupById } = useMutation({
    mutationKey: ["delete-group"],
    mutationFn: ({ groupId }: { groupId: string }) =>
      fetchApi.delete(`/groups/${groupId}`),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["groups"],
      });
    },
  });

  const onRequestDelete = (groupId: string) => {
    pushModal("ConfirmationModal", {
      title: `Confirmar deleção do grupo`,
      confirmation: () => deleteGroupById({ groupId }),
    });
  };

  const onRequestUpdate = (groupId: string) => {
    pushModal("UpdateGroupModal", {
      groupId: groupId,
    });
  };

  const table = useGenericTable({
    columns: getGroupTableColumns({
      onRequestDelete: onRequestDelete,
      onRequestUpdate: onRequestUpdate,
    }),
    data: groups,
  });

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Usuários</PageTitle>
        <PageDescription>
          Gerencie todos os usuários que estão cadastrados no sistema.
        </PageDescription>
      </PageHeader>
      <PageContent className="flex flex-col gap-4">
        <CardButton
          className="w-fit"
          onClick={() => pushModal("CreateGroupModal")}
        >
          <CardButtonContent>
            <CardButtonIcon>
              <Building size={20} weight="fill" />
            </CardButtonIcon>
            <CardButtonDescription>Novo grupo</CardButtonDescription>
          </CardButtonContent>
        </CardButton>
        <div className="space-y-4">
          <DataTableFacetedFilter
            label="Categoria do grupo"
            column={table.getColumn("type")!}
            options={[
              {
                label: "Setor de Aprovação",
                value: "sector",
              },
              {
                label: "Financeiro",
                value: "financial",
              },
              {
                label: "Contabilidade",
                value: "legal",
              },
            ]}
          />
          <DataTableViewOptions table={table}></DataTableViewOptions>
          <DataTable table={table} />
          <DataTablePagination table={table} />
        </div>
      </PageContent>
    </PageContainer>
  );
}
