export function formatCNPJ(cnpj: string): string {
  if (!cnpj) {
    return "";
  }

  return cnpj.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
    "$1.$2.$3/$4-$5"
  );
}

export function formatCPF(cpf: string): string {
  return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
}

export function formatZipCode(cep: string): string {
  if (!cep) {
    return "";
  }

  return cep.replace(/^(\d{5})(\d{3})$/, "$1-$2");
}

export function onlyNumbers(value: string): string {
  if (!value) {
    return "";
  }

  return value.replace(/\D/g, "");
}

const format = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

export function formatMoney(value: string | number) {
  if (typeof value === "string") {
    value = parseFloat(value);
  }

  return format.format(value);
}
