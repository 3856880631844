import * as React from "react";
import {
  Building2,
  Files,
  Frame,
  LifeBuoy,
  PieChart,
  Send,
  Square,
} from "lucide-react";

import { NavMain } from "@/components/navbar/nav-main";
import { NavProjects } from "@/components/navbar/nav-projects";
import { NavSecondary } from "@/components/navbar/nav-secondary";
import { NavUser } from "@/components/navbar/nav-user";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "@/components/ui/sidebar";
import { useSuspenseSessionContext } from "@/hooks/use-session-context.ts";
import { Link } from "react-router-dom";
import { BuildingOffice, Basket, HardHat, Gear } from "@phosphor-icons/react";
import { NotificationNav } from "./notification-nav";

const data = {
  navMain: [
    {
      title: "Cadastro",
      icon: BuildingOffice,
      isActive: true,
      items: [
        {
          title: "Empresas",
          url: "/companies",
        },
        {
          title: "Fornecedores",
          url: "/vendors",
        },
      ],
    },
    {
      title: "Matriz de Avaliação",
      icon: Gear,
      isActive: true,
      items: [
        {
          title: "Visão geral",
          url: "/evaluation",
        },
        // {
        //   title: "Configurações",
        //   url: "/evaluation/settings",
        // },
      ],
    },
    {
      title: "Notas de Produto",
      icon: Basket,
      isActive: true,
      items: [
        {
          title: "Emitidas",
          url: "/invoices/product/sent",
        },
        {
          title: "Recebidas",
          url: "/invoices/product/received",
        },
      ],
    },
    {
      title: "Notas de Serviço",
      icon: HardHat,
      isActive: true,
      items: [
        {
          title: "Emitidas",
          url: "/invoices/service/sent",
        },
        // {
        //   title: "Recebidas",
        //   url: "/invoices/service/received",
        // },
      ],
    },
    {
      title: "Lotes de Notas",
      icon: Files,
      isActive: true,
      items: [
        {
          title: "Gerenciar",
          url: "/invoices/batches",
        },
      ],
    },
    {
      title: "Sistema",
      icon: Building2,
      isActive: true,
      items: [
        {
          title: "Usuários",
          url: "/users",
        },
        {
          title: "Grupos",
          url: "/groups",
        },
      ],
    },
  ],
  navSecondary: [
    {
      title: "Suporte",
      url: "#",
      icon: LifeBuoy,
    },
    {
      title: "Feedback",
      url: "#",
      icon: Send,
    },
    {
      title: "Base de conhecimento",
      url: "#",
      icon: LifeBuoy,
    },
  ],
  projects: [
    {
      name: "Emitir NFS-e",
      url: "/invoices/service/new",
      icon: Frame,
    },
    {
      name: "Emitir NF-e",
      url: "/invoices/product/new",
      icon: PieChart,
    },
  ],
};

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const { session } = useSuspenseSessionContext();

  return (
    <Sidebar variant="inset" {...props}>
      <SidebarHeader className="gap-4">
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton size="lg" asChild>
              <Link to="/">
                <div className="flex aspect-square size-8 items-center justify-center rounded-sm bg-white text-black">
                  <Square className="size-4 rotate-45" />
                </div>
                <div className="flex items-end">
                  <div className="truncate text-2xl uppercase space-x-0.5">
                    <span className="font-bold font-raleway">TW</span>
                    <span className="font-raleway">Docs</span>
                  </div>
                </div>
              </Link>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
        <SidebarMenu>
          {/*<SidebarMenuItem>*/}
          {/*  <SearchNav />*/}
          {/*</SidebarMenuItem>*/}
          <SidebarMenuItem>
            <NotificationNav />
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <NavMain items={data.navMain} />
        <NavProjects projects={data.projects} />
        <NavSecondary />
      </SidebarContent>
      <SidebarFooter>
        <NavUser
          user={{
            name: session.name,
            email: session.email,
          }}
        />
      </SidebarFooter>
    </Sidebar>
  );
}
