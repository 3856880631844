import type { UseFormReturn } from "react-hook-form";
import type { NewProductInvoice } from "@shared/schemas/invoices/nfe/new_product.ts";
import { FormFieldSimple } from "@/components/ui/form.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";
import { NFeModalidadeFrete } from "@shared/schemas/invoices/nfe/focus/focus_product.ts";
import { Input } from "@/components/ui/input.tsx";
import { Checkbox } from "@/components/ui/checkbox.tsx";
import { Label } from "@/components/ui/label.tsx";
import { SelectPopover } from "@/components/select-popover.tsx";
import { CFOP } from "@shared/constants/cfop.ts";

interface ComponentProps {
  form: UseFormReturn<NewProductInvoice>;
}

export function NewInvoiceProductFreight({ form }: ComponentProps) {
  const noFreightModality =
    form.watch("freight.modality") === NFeModalidadeFrete.SEM_FRETE;
  const informIcms = form.watch("freight.manuallyInformIcmsTribute");

  return (
    <div className="grid grid-cols-6 gap-3">
      <FormFieldSimple
        className="col-span-6"
        control={form.control}
        name="freight.modality"
        label="Modalidade do Frete"
        required
        render={({ field }) => (
          <Select value={field.value} onValueChange={field.onChange}>
            <SelectTrigger>
              <SelectValue placeholder="Selecione" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value={NFeModalidadeFrete.POR_CONTA_DO_REMETENTE}>
                0 - Por conta do remetente
              </SelectItem>
              <SelectItem value={NFeModalidadeFrete.POR_CONTA_DO_DESTINATARIO}>
                1 - Por conta do destinatário
              </SelectItem>
              <SelectItem value={NFeModalidadeFrete.POR_CONTA_DE_TERCEIROS}>
                2 - Por conta de terceiros
              </SelectItem>
              <SelectItem
                value={
                  NFeModalidadeFrete.TRANSPORTE_PROPRIO_POR_CONTA_DO_REMETENTE
                }
              >
                3 - Transporte próprio por conta do remetente
              </SelectItem>
              <SelectItem
                value={
                  NFeModalidadeFrete.TRANSPORTE_PROPRIO_POR_CONTA_DO_DESTINATARIO
                }
              >
                4 - Transporte próprio por conta do destinatário
              </SelectItem>
              <SelectItem value={NFeModalidadeFrete.SEM_FRETE}>
                9 - Sem ocorrência de frete
              </SelectItem>
            </SelectContent>
          </Select>
        )}
      />
      <FormFieldSimple
        control={form.control}
        name="freight.carrierName"
        label="Nome do Transportador"
        render={({ field }) => (
          <Input
            {...field}
            placeholder="Ex.: Empresa de Serviços Ltda."
            disabled={noFreightModality}
          />
        )}
      />
      <FormFieldSimple
        control={form.control}
        name="freight.carrierDocument"
        label="Documento (CNPJ ou CPF)"
        render={({ field }) => (
          <Input
            {...field}
            placeholder="Ex.: 123456789"
            disabled={noFreightModality}
          />
        )}
      />
      <FormFieldSimple
        control={form.control}
        name="freight.carrierStateRegistration"
        label="Inscrição Estadual"
        render={({ field }) => (
          <Input
            {...field}
            placeholder="Ex.: 123456789"
            disabled={noFreightModality}
          />
        )}
      />

      <FormFieldSimple
        control={form.control}
        name="freight.carrierAddress.complete"
        label="Logradouro Completo"
        render={({ field }) => (
          <Input
            {...field}
            placeholder="Ex.: Rua Exemplo, 123, Bairro"
            disabled={noFreightModality}
          />
        )}
      />
      <FormFieldSimple
        control={form.control}
        name="freight.carrierAddress.state"
        label="UF"
        render={({ field }) => (
          <Input
            {...field}
            placeholder="Ex.: SP"
            disabled={noFreightModality}
          />
        )}
      />
      <FormFieldSimple
        control={form.control}
        name="freight.carrierAddress.city"
        label="Município"
        render={({ field }) => (
          <Input
            {...field}
            placeholder="Ex.: São Paulo"
            disabled={noFreightModality}
          />
        )}
      />
      <FormFieldSimple
        control={form.control}
        name="freight.carrierVehicle.plate"
        label="Placa do Veículo"
        render={({ field }) => (
          <Input
            {...field}
            placeholder="Ex.: ABC1A00"
            disabled={noFreightModality}
          />
        )}
      />
      <FormFieldSimple
        control={form.control}
        name="freight.carrierVehicle.state"
        label="UF do Veículo"
        render={({ field }) => (
          <Input
            {...field}
            placeholder="Ex.: SP"
            disabled={noFreightModality}
          />
        )}
      />
      <FormFieldSimple
        control={form.control}
        name="freight.carrierVehicle.rntcRegistration"
        label="Reg. Nac. Trans. Carga (RNTC)"
        render={({ field }) => (
          <Input
            {...field}
            placeholder="Ex.: 12345612"
            disabled={noFreightModality}
          />
        )}
      />
      <FormFieldSimple
        control={form.control}
        name="freight.carrierVehicle.wagonIdentification"
        label="Identificação Vagão"
        render={({ field }) => (
          <Input
            {...field}
            placeholder="Ex.: 12345612"
            disabled={noFreightModality}
          />
        )}
      />
      <FormFieldSimple
        control={form.control}
        name="freight.carrierVehicle.ferryIdentification"
        label="Identificação Balsa"
        render={({ field }) => (
          <Input
            {...field}
            placeholder="Ex.: 12345612"
            disabled={noFreightModality}
          />
        )}
      />
      <div className="col-span-1" />
      <FormFieldSimple
        className="col-span-6"
        control={form.control}
        name="freight.manuallyInformIcmsTribute"
        render={({ field }) => (
          <div className="flex items-center gap-2">
            <Checkbox
              id="informIcms"
              checked={field.value}
              onCheckedChange={(checked) => {
                form.resetField("freight.tributes");
                field.onChange(checked);
              }}
            />
            <Label htmlFor="informIcms">
              Informar retenções de ICMS do transporte
            </Label>
          </div>
        )}
      />
      {informIcms && (
        <>
          <div className="col-span-6">
            <h1>Retenção ICMS do transporte</h1>
          </div>
          <FormFieldSimple
            control={form.control}
            name="freight.tributes.serviceValue"
            label="Valor do serviço"
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Ex.: 100"
                disabled={noFreightModality}
              />
            )}
          />
          <FormFieldSimple
            control={form.control}
            name="freight.tributes.icmsBaseRetention"
            label="BC Retenção do ICMS"
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Ex.: 100"
                disabled={noFreightModality}
              />
            )}
          />
          <FormFieldSimple
            control={form.control}
            name="freight.tributes.retentionAliquot"
            label="Alíquota da Retenção"
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Ex.: 4"
                disabled={noFreightModality}
              />
            )}
          />
          <FormFieldSimple
            control={form.control}
            name="freight.tributes.transportationCfop"
            label="CFOP do Transporte"
            render={({ field }) => (
              <SelectPopover
                items={CFOP.freighList.map((cfop) => ({
                  value: cfop.value,
                  label: `${cfop.value} - ${
                    cfop.label.length > 64
                      ? cfop.label.slice(0, 64) + "..."
                      : cfop.label
                  }`,
                  keywords: [cfop.label],
                }))}
                onValueChange={(value) => {
                  field.onChange(value);
                }}
                value={field.value}
                disabled={noFreightModality}
              />
            )}
          />
          <FormFieldSimple
            control={form.control}
            name="freight.tributes.icmsState"
            label="UF"
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Ex.: SP"
                disabled={noFreightModality}
              />
            )}
          />
          <FormFieldSimple
            control={form.control}
            name="freight.tributes.icmsCityCode"
            label="Município"
            render={({ field }) => (
              <Input
                {...field}
                placeholder="Ex.: São Paulo"
                disabled={noFreightModality}
              />
            )}
          />
        </>
      )}
    </div>
  );
}
