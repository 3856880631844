import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog.tsx";
import { useForm, useWatch } from "react-hook-form";
import { z } from "zod";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchApi, getApiData, isApiError } from "@/lib/api.ts";
import { Form, FormFieldSimple } from "@/components/ui/form.tsx";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "@/components/ui/input.tsx";
import { toast } from "sonner";
import { popModal } from "@/modals";
import { PendingButton } from "@/components/pending-button.tsx";
import { MultipleSelector } from "@/multiselect.tsx";
import { SelectPopover } from "@/components/select-popover.tsx";

const updateGroupSchema = z.object({
  name: z.string().min(1, "Use um nome válido"),
  type: z.enum(["sector", "financial", "legal"]),
  admins: z.array(z.string()).min(1, "Selecione um administrador do grupo"),
  members: z.array(z.string()),
});

export function UpdateGroupModal({ groupId }: { groupId: string }) {
  const queryClient = useQueryClient();

  const { data: currentGroup, isLoading: isGroupLoading } = useQuery({
    queryKey: ["groups", groupId],
    queryFn: () =>
      fetchApi.get(`/groups/${groupId}`).then(
        getApiData<{
          id: string;
          name: string;
          type: string;
          admins: string[];
          members: string[];
        }>
      ),
  });

  const { data: response, isLoading } = useQuery({
    queryKey: ["users"],
    queryFn: async () =>
      fetchApi.get("/users?status=active").then(
        getApiData<{
          users: {
            id: string;
            name: string;
            email: string;
          }[];
        }>
      ),
  });

  const form = useForm<{
    name: string;
    admins: string[];
    members: string[];
  }>({
    resolver: zodResolver(updateGroupSchema),
    values: currentGroup,
  });

  const { mutate: createGroup, isPending } = useMutation({
    mutationKey: ["update-group"],
    mutationFn: async (data: { name: string; admins: string[] }) =>
      fetchApi.put(`/groups/${groupId}`, data),
    onError: (error) => {
      if (isApiError(error)) {
        toast.error(
          error.response?.data.error.message || "Ocorreu um erro desconhecido."
        );
      } else {
        toast.error("Ocorreu um erro desconhecido.");
      }
    },
    onSuccess: async (_, variables) => {
      toast.success(`Grupo ${variables.name} atualizado com sucesso.`);
      await queryClient.invalidateQueries({
        queryKey: ["groups"],
      });

      popModal("UpdateGroupModal");
    },
  });

  const [admins, members] = useWatch({
    control: form.control,
    name: ["admins", "members"],
  });

  const availableUsers = response?.users.filter(
    (user) => !admins?.includes(user.id)
  );
  const availableAdmins = response?.users.filter(
    (user) => !members?.includes(user.id)
  );

  if (isGroupLoading) {
    return (
      <DialogContent>
        <span>Carregando informações do grupo</span>
      </DialogContent>
    );
  }

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Editando grupo</DialogTitle>
        <DialogDescription>
          Você está adicionando um novo grupo ao sistema.
        </DialogDescription>
      </DialogHeader>

      <Form {...form}>
        <form
          className="flex flex-col gap-4"
          onSubmit={form.handleSubmit(async (data) => createGroup(data))}
        >
          <FormFieldSimple
            name="name"
            label="Nome"
            required={true}
            render={({ field }) => (
              <Input
                {...field}
                type="text"
                placeholder="Nome do grupo"
                className="w-full"
              />
            )}
          />

          <FormFieldSimple
            name="type"
            label="Categoria do grupo"
            description="A categoria do grupo é utilizada para segmentação das etapas na Matriz de Avaliação"
            required={true}
            render={({ field }) => (
              <SelectPopover
                items={[
                  {
                    label: "Setor",
                    value: "sector",
                  },
                  {
                    label: "Fiscal",
                    value: "legal",
                  },
                  {
                    label: "Financeiro",
                    value: "financial",
                  },
                ]}
                value={field.value}
                onValueChange={field.onChange}
                disabled={isLoading}
              ></SelectPopover>
            )}
          />

          <FormFieldSimple
            name="admins"
            label="Administradores do grupo"
            description="Um administrador poderá adicionar e remover usuários do grupo, além de ter aprovação máxima quando a Matriz de Avaliação estiver ativa."
            required={true}
            render={({ field }) => (
              <MultipleSelector
                items={
                  availableAdmins?.map((user) => ({
                    label: user.name,
                    value: user.id,
                  })) ?? []
                }
                placeholder="Selecione um administrador"
                value={field.value}
                onValueChange={field.onChange}
                disabled={isLoading}
                emptyIndicator={<span>Nenhum usuário encontrado.</span>}
              ></MultipleSelector>
            )}
          />

          <FormFieldSimple
            name="members"
            label="Membros do grupo"
            required={true}
            render={({ field }) => (
              <MultipleSelector
                items={
                  availableUsers?.map((user) => ({
                    label: user.name,
                    value: user.id,
                  })) ?? []
                }
                placeholder="Selecione um membro"
                value={field.value}
                onValueChange={field.onChange}
                disabled={isLoading}
                emptyIndicator={<span>Nenhum usuário encontrado.</span>}
              ></MultipleSelector>
            )}
          />

          <PendingButton type="submit" isPending={isPending}>
            Salvar alterações
          </PendingButton>
        </form>
      </Form>
    </DialogContent>
  );
}
