import type { NewProductInvoice } from "@shared/schemas/invoices/nfe/new_product.ts";
import { FormFieldSimple } from "@/components/ui/form.tsx";
import { Textarea } from "@/components/ui/textarea.tsx";
import { useFormContext } from "react-hook-form";

export function NewInvoiceProductOthers() {
  const form = useFormContext<NewProductInvoice>();

  return (
    <div className="grid grid-cols-6 gap-3">
      <FormFieldSimple
        className="col-span-6"
        control={form.control}
        name="others.complementaryInformation"
        label="Informações Complementares do Contribuinte"
        render={({ field }) => (
          <Textarea {...field} placeholder="Ex.: Informação adicional" />
        )}
      />
      <FormFieldSimple
        className="col-span-6"
        control={form.control}
        name="others.taxInformation"
        label="Informações ao Fisco"
        render={({ field }) => (
          <Textarea {...field} placeholder="Ex.: Informação fiscal" />
        )}
      />
    </div>
  );
}
