import { onlyNumbers } from "@shared/format";
import type { ComponentPropsWithoutRef } from "react";
import { NumberFormatBase } from "react-number-format";
import { Input } from "@/components/ui/input.tsx";
import { forwardRef } from "react";

interface Props
  extends Omit<
    ComponentPropsWithoutRef<typeof NumberFormatBase>,
    "customInput"
  > {}

export const PhoneInput = forwardRef(({ ...props }: Props, ref) => {
  return (
    <NumberFormatBase
      getInputRef={ref}
      customInput={Input}
      format={(numStr) => {
        const numbers = onlyNumbers(numStr);

        if (numbers.length < 10) {
          return numbers;
        }

        const regex = /^(\d{2})(\d{4,5})(\d{4})$/;

        const match = regex.exec(numbers);

        if (!match) {
          return numbers;
        }

        return `(${match[1]}) ${match[2]}-${match[3]}`;
      }}
      {...props}
    />
  );
});
