import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover.tsx";
import { Button } from "@/components/ui/button.tsx";
import { cn } from "@/lib/utils.ts";
import { Check, ChevronsUpDown } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command.tsx";
import type { ComponentPropsWithoutRef } from "react";
import { useLocationStates } from "@/hooks/data-access/locations-query.ts";

interface Props {
  value?: string;
  onValueChange: (value: { code: string; name: string }) => void;
}

export function SelectStateMenu({
  onValueChange,
  value,
  ...props
}: Omit<ComponentPropsWithoutRef<typeof PopoverTrigger>, "value"> & Props) {
  const { data: states } = useLocationStates();
  const selectedState = states?.states.find((city) => city.code === value);

  return (
    <Popover>
      <PopoverTrigger asChild={true} {...props}>
        <Button
          type="button"
          variant="outline"
          role="combobox"
          className={cn(
            "w-full justify-between",
            !value && "text-muted-foreground"
          )}
        >
          {selectedState ? selectedState.name : "Escolha o estado"}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0">
        <Command>
          <CommandInput placeholder="Procurar cidade..." />
          <CommandList>
            <CommandEmpty>Sem resultados</CommandEmpty>
            <CommandGroup>
              {states?.states.map((state) => (
                <CommandItem
                  value={`${state.code}_${state.name}`}
                  key={state.name}
                  onSelect={() => onValueChange(state)}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      state.code === selectedState?.code
                        ? "opacity-100"
                        : "opacity-0"
                    )}
                  />
                  {state.name}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
