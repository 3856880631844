import { DataTable } from "@/components/table/data-table.tsx";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { DataTablePagination } from "@/components/table/data-table-pagination.tsx";
import { DataTableFacetedFilter } from "@/components/table/data-table-faceted-filter.tsx";
import { DataTableSearch } from "@/components/table/data-table-search.tsx";
import {
  PageContainer,
  PageContent,
  PageDescription,
  PageHeader,
  PageTitle,
} from "@/components/layout/page.tsx";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { productReceivedColumns } from "@/pages/invoices/product-received/product-received-columns.tsx";
import { usePaginationSearchParams } from "@/hooks/use-pagination-search-params.ts";
import { type ApiResponse, fetchApi } from "@/lib/api.ts";
import { useFilterSearchParams } from "@/hooks/use-filter-search-params.ts";
import { parseAsArrayOf, parseAsString } from "nuqs";
import { useDebounce } from "@/hooks/use-debounce.tsx";
import { useOrderBySearchParams } from "@/hooks/use-order-by-search-params.ts";
import { DataTableViewOptions } from "@/components/data-table/data-table-view-options.tsx";

type ReceivedProductInvoiceResponse = {
  invoices: Array<{
    id: string;
    status: string;
    type: "product" | "service";
    issuer: {
      document: string;
      name?: string;
    };
    recipient: {
      document: string;
      name: string;
    };
    invoice: {
      value: string;
      issuedAt: string;
      number: string;
    };
    approvalStatus: string;
  }>;
  pagination: {
    total: number;
    totalPages: number;
  };
};

export function ReceivedProductInvoices() {
  const { paginationState, handleTablePaginationChange } =
    usePaginationSearchParams();

  const { sortState, tableSortingState, handleTableSortingChange } =
    useOrderBySearchParams({
      orderBy: "issuedAt",
      orderDirection: "desc",
    });

  const { filters, tableFilterState, handleTableFilterChange } =
    useFilterSearchParams({
      status: parseAsArrayOf(parseAsString).withDefault([]),
      approvalStatus: parseAsArrayOf(parseAsString).withDefault([]),
      issuer: parseAsString,
      recipient: parseAsString,
    });

  const debouncedFilters = useDebounce(filters, 500);
  const debouncedPaginationState = useDebounce(paginationState, 500);

  const { data: invoices } = useQuery({
    queryKey: [
      `/invoices/products/received`,
      debouncedPaginationState,
      debouncedFilters,
      sortState,
    ],
    queryFn: () =>
      fetchApi
        .get<ApiResponse<ReceivedProductInvoiceResponse>>(
          "/invoices/products/received",
          {
            params: {
              limit: debouncedPaginationState.pageSize,
              page: debouncedPaginationState.pageIndex + 1,
              ...debouncedFilters,
              ...sortState,
            },
          }
        )
        .then((res) => res.data.data),
    placeholderData: keepPreviousData,
  });

  const table = useReactTable({
    columns: productReceivedColumns,
    data: invoices?.invoices ?? [],
    state: {
      pagination: paginationState,
      columnFilters: tableFilterState,
      sorting: tableSortingState,
    },
    onPaginationChange: handleTablePaginationChange,
    onSortingChange: handleTableSortingChange,
    onColumnFiltersChange: handleTableFilterChange,
    manualPagination: true,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
    pageCount: invoices?.pagination?.totalPages ?? -1,
  });

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Notas de Produto Recebidas</PageTitle>
        <PageDescription>
          Gerencie todas as notas recebidas até o momento pelo nosso sistema.
        </PageDescription>
      </PageHeader>
      <PageContent>
        <div className="space-y-4">
          <div className="flex items-center justify-between flex-wrap min-w-0 gap-2">
            <div className="flex items-center gap-2 min-w-0">
              <DataTableFacetedFilter
                title="Status Sefaz"
                column={table.getColumn("status")}
                options={[
                  {
                    label: "Autorizada",
                    value: "authorized",
                  },
                  {
                    label: "Cancelada",
                    value: "cancelled",
                  },
                  {
                    label: "Denegada",
                    value: "denied",
                  },
                ]}
              />
              <DataTableFacetedFilter
                title="Matriz de Avaliação"
                column={table.getColumn("approvalStatus")}
                options={[
                  {
                    label: "Aguardando Análise",
                    value: "pending",
                  },
                  {
                    label: "Aprovada",
                    value: "approved",
                  },
                  {
                    label: "Rejeitada",
                    value: "rejected",
                  },
                ]}
              />
              <DataTableSearch
                placeholder="Emitente"
                column={table.getColumn("issuer")!}
              />
              <DataTableSearch
                placeholder="Tomador"
                column={table.getColumn("recipient")!}
              />
            </div>
            <DataTableViewOptions
              table={table}
              columns={{
                status: "Status",
                approvalStatus: "Matriz de Avaliação",
              }}
            />
          </div>
          <DataTable table={table} />
          <DataTablePagination table={table} />
        </div>
      </PageContent>
    </PageContainer>
  );
}
