import { z } from "zod";
import { isCNPJ, isCPF } from "validation-br";

export enum ServiceLocationType {
  ISSUER = "1",
  RECIPIENT = "2",
  OTHER = "3",
}

const issuerSchema = z.object({
  document: z.string().min(1, "O CNPJ emissor é obrigatório"),
  operationNature: z.string().optional(),
  specialRegimeTributary: z.string().optional(),
  culturalSupporter: z.boolean().optional(),
});

const recipientAddressSchema = z.object({
  street: z.string().min(1, "O endereço é obrigatório"),
  number: z.string().min(1, "O número é obrigatório"),
  complement: z.string().optional(),
  district: z
    .string()
    .min(1, "O bairro é obrigatório, se não houver, informe 'S/N'"),
  state: z.string().min(1, "UF é obrigatório"),
  zipCode: z.string().min(1, "CEP é obrigatório"),
  cityCode: z.string().min(1, "Código da cidade é obrigatório"),
});

const serviceAddressSchema = z.object({
  street: z.string().optional(),
  number: z.string().optional(),
  complement: z.string().optional(),
  district: z.string().optional(),
  zipCode: z.string().optional(),
});

const recipientSchema = z.object({
  document: z
    .string()
    .min(1, "O CNPJ do tomador é obrigatório")
    .refine((value) => isCPF(value) || isCNPJ(value), {
      message: "O CNPJ do tomador é inválido",
    }),
  documentType: z.enum(["CPF", "CNPJ"]).default("CNPJ"),
  municipalRegistration: z.string().optional(),
  stateRegistration: z.string().optional(),
  companyName: z
    .string()
    .min(1, "A razão social ou nome completo é obrigatório"),
  email: z.string().optional(),
  phone: z.string().optional(),
  address: recipientAddressSchema,
});

const serviceItemSchema = z
  .object({
    value: z.string().min(1, "O valor do serviço é obrigatório"),
    description: z
      .string({
        message: "A descrição do serviço é obrigatória",
      })
      .min(1, "A descrição do serviço é obrigatório"),
    municipalTaxCode: z.string().optional(),
    item: z.string().min(1, "O item de lei é obrigatório"),
    issRetained: z.boolean({
      message: "É necessário informar se o ISS na fonte é retido",
    }),

    deductions: z.string().optional(),
    pis: z.string().optional(),
    cofins: z.string().optional(),
    ir: z.string().optional(),
    inss: z.string().optional(),
    csll: z.string().optional(),
    issValue: z.string().optional(),
    issValueRetained: z.string().optional(),
    otherRetentions: z.string().optional(),
    baseValue: z.string().optional(),
    aliquot: z.string().optional(),
    unconditionalDiscount: z.string().optional(),
    conditionalDiscount: z.string().optional(),
    cnae: z.string().optional(),
    cityCode: z.string().optional(),
    addressLocation: z.nativeEnum(ServiceLocationType).optional(),
    address: serviceAddressSchema.optional(),
  })
  .superRefine((value, ctx) => {
    const baseCalculation =
      Number(value.value || "0") - Number(value.deductions || "0");

    if (baseCalculation < 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["value"],
        message: "Verifique o valor informado para serviço",
      });

      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: ["deductions"],
        message: "Verifique o valor informado para deduções",
      });
    }

    if (value.addressLocation === ServiceLocationType.OTHER) {
      if (!value.cityCode) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["cityCode"],
          message: "O campo cidade é obrigatório",
        });
      }

      if (!value.address?.street) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["address", "street"],
          message: "O logradouro é obrigatório",
        });
      }

      if (!value.address?.number) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["address", "number"],
          message: "O número é obrigatório",
        });
      }

      if (!value.address?.district) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["address", "district"],
          message: "O bairro é obrigatório",
        });
      }

      if (!value.address?.zipCode) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["address", "zipCode"],
          message: "O CEP é obrigatório",
        });
      }
    }
  });

export const serviceInvoiceSchema = z.object({
  issuedAt: z.coerce.string().min(1, "A data de emissão é obrigatória"),
  issuer: issuerSchema,
  recipient: recipientSchema,
  service: serviceItemSchema,
});
