import { z } from "zod";

export const currencySchema = z
  .union([z.string().transform((x) => x.replace(/[^0-9.-]+/g, "")), z.number()])
  .pipe(z.coerce.number().min(0.01).max(999999999));

export const percentageSchema = z
  .union([z.string().transform((x) => x.replace(/[^0-9.-]+/g, "")), z.number()])
  .pipe(z.coerce.number().min(0).max(100));

export const addressSchema = z.object({
  street: z
    .string({
      required_error: "O endereço é obrigatório",
    })
    .min(1, "O endereço é obrigatório"),
  number: z
    .string({
      required_error: "O número é obrigatório",
    })
    .min(1, "O número é obrigatório"),
  district: z
    .string({
      required_error: "O bairro é obrigatório",
    })
    .min(1, "O bairro é obrigatório"),
  zipCode: z
    .string({
      required_error: "O CEP é obrigatório",
    })
    .length(8, "O CEP é obrigatório"),
  stateCode: z
    .string({
      required_error: "O estado é obrigatório",
    })
    .length(2, "O estado é obrigatório"),
  cityCode: z
    .string({
      required_error: "O município é obrigatório",
    })
    .length(7, "O município é obrigatório"),
  complement: z.string().nullish().default(""),
});

export type Address = z.infer<typeof addressSchema>;
