import {
  PageContainer,
  PageContent,
  PageDescription,
  PageHeader,
  PageTitle,
} from "@/components/layout/page.tsx";
import { useSearchParams } from "react-router-dom";
import { DataTable, useGenericTable } from "@/components/table/data-table.tsx";
import { DataTablePagination } from "@/components/table/data-table-pagination.tsx";
import { fetchApi, getApiData } from "@/lib/api.ts";
import { useQuery } from "@tanstack/react-query";
import type { EvaluationList } from "@shared/types/evaluation.ts";
import { documentListColumns } from "@/pages/evaluation/components/document-list-columns.tsx";

export function EvaluationDocumentList() {
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status") || "all";
  const beacon = searchParams.get("beacon") || "all";

  const { data } = useQuery({
    queryKey: ["retrieve-evaluation-document-list", status, beacon],
    queryFn: () =>
      fetchApi
        .get(`/evaluation/list?status=${status}&beacon=${beacon}`)
        .then(getApiData<EvaluationList[]>),
    initialData: [
      {
        id: "1",
        company: {
          id: "1",
          name: "Empresa 1",
          document: "1234567890",
        },
        vendor: {
          id: "1",
          name: "Fornecedor 1",
          document: "1234567890",
        },
        invoice: {
          id: "1",
          number: "123",
          issuedAt: "2024-12-11T00:00:00.000Z",
        },
        value: "1000",
        step: "SR",
        completed: true,
        updatedAt: "2024-12-11T00:00:00.000Z",
        createdAt: "2024-12-11T00:00:00.000Z",
      },
      {
        id: "2",
        company: {
          id: "1",
          name: "Empresa 3",
          document: "1234567890",
        },
        vendor: {
          id: "1",
          name: "Fornecedor 2",
          document: "1234567890",
        },
        invoice: {
          id: "1",
          number: "22",
          issuedAt: "2024-12-11T00:00:00.000Z",
        },
        value: "1345.78",
        step: "VC",
        completed: false,
        updatedAt: "2024-12-11T00:00:00.000Z",
        createdAt: "2024-12-11T00:00:00.000Z",
      },
    ],
  });

  console.log(data);

  const table = useGenericTable({
    columns: documentListColumns,
    data: data,
  });

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Matriz de avaliação</PageTitle>
        <PageDescription>
          Gerencie os documentos integrados no sistema
        </PageDescription>
      </PageHeader>
      <PageContent>
        <div className="space-y-4">
          <DataTable table={table} />
          <DataTablePagination table={table} />
        </div>
      </PageContent>
    </PageContainer>
  );
}
