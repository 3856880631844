import { z } from "zod";
import { onlyNumbers } from "../format";
import { addressSchema } from "./common";

export enum RegimeTributary {
  SIMPLES_NACIONAL = 1,
  SIMPLES_NACIONAL_EXCESSO = 2,
  REGIME_NORMAL = 3,
  SIMPLES_NACIONAL_MEI = 4,
}

export const getTributaryRegimeName = (regime?: number | null) => {
  switch (regime) {
    case RegimeTributary.SIMPLES_NACIONAL:
      return "Simples Nacional";
    case RegimeTributary.SIMPLES_NACIONAL_EXCESSO:
      return "Simples Nacional - Excesso de Sublimite de Receita Bruta";
    case RegimeTributary.REGIME_NORMAL:
      return "Regime Normal";
    case RegimeTributary.SIMPLES_NACIONAL_MEI:
      return "Simples Nacional MEI";
    default:
      return "Não informado";
  }
};

export const companySchema = z.object({
  name: z
    .string({
      required_error: "O nome da empresa é obrigatório",
    })
    .min(1, "O nome da empresa é obrigatório"),
  fantasyName: z
    .string({
      required_error: "O nome fantasia é obrigatório",
    })
    .min(1, "O nome fantasia é obrigatório"),
  email: z
    .string()
    .min(1, "Email é obrigatório")
    .email("Email em formato inválido")
    .transform((value) => value.toLowerCase()),
  phone: z
    .string()
    .min(1, "Telefone é obrigatório")
    .transform((value) => onlyNumbers(value)),
  document: z
    .string({
      required_error: "O CNPJ é obrigatório",
    })
    .refine((value) => {
      return onlyNumbers(value).length === 14;
    }, "CNPJ inválido"),
  tributaryRegime: z.nativeEnum(RegimeTributary),
  municipalRegistration: z
    .string()
    .nullish()
    .transform((value) => value || null),
  stateRegistration: z
    .string()
    .nullish()
    .transform((value) => value || null),
});

export const companyWithAddressSchema = companySchema.extend({
  address: addressSchema,
});

export const companiesSchema = companySchema
  .pick({
    document: true,
    name: true,
  })
  .extend({
    id: z.string(),
  });

export type CompanyWithAddress = z.infer<typeof companyWithAddressSchema>;
export type Companies = z.infer<typeof companiesSchema>;

export type Company = z.infer<typeof companySchema>;
