import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import {
  AlertTriangleIcon,
  CheckCheck,
  FileCode,
  FileText,
  FileX,
} from "lucide-react";

import type { SelectProductInvoiceItem } from "@shared/types/invoices/product";
import { type SelectProductInvoice } from "@shared/types/invoices/product";
import { useQuery } from "@tanstack/react-query";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { DownloadTooltip } from "@/components/download-tooltip";
import { format } from "date-fns";

interface Props {
  id: string;
}

type ApprovalType = {
  userId: string;
  name: string;
  status: string;
  reason: string;
  updatedAt: string;
  createdAt: string;
};

export default function ViewReceivedProductInvoice({ id }: Props) {
  const { data, isLoading } = useQuery<
    SelectProductInvoice & {
      issuedAt: string;
      items: SelectProductInvoiceItem[];
      externalId: string;
      approval: ApprovalType;
      vendor: {
        name: string;
        document: string;
      };
      company: {
        name: string;
        document: string;
      };
      links: {
        pdf: string | null;
        xml: string | null;
      };
    }
  >({
    queryKey: [`/invoices/received/product/${id}`],
  });

  if (!data || isLoading) {
    return null;
  }

  const issuedAt = data.issuedAt
    ? new Date(data.issuedAt).toLocaleDateString()
    : "";

  const isPdfAvailable = !!data.links.pdf;

  return (
    <DialogContent
      onOpenAutoFocus={(e) => e.preventDefault()}
      className={`max-w-5xl flex flex-col ${isPdfAvailable ? "h-[95vh]" : "h-auto"} overflow-hidden`}
    >
      <DialogHeader className="font-medium text-lg">
        <DialogTitle>Visualizando Nota Fiscal recebida (NF-e)</DialogTitle>
        <DialogDescription>
          <p className="text-xs">Chave de acesso: {data.externalId}</p>
          <div className="flex justify-between items-center">
            <p className="text-xs">Data de emissão: {issuedAt}</p>
          </div>
        </DialogDescription>
      </DialogHeader>

      <div
        className={`flex flex-col ${isPdfAvailable ? "flex-grow" : ""} overflow-hidden space-y-4`}
      >
        <div className="flex-shrink-0 space-y-4">
          {data?.approval?.status === "approved" && (
            <Alert className="bg-green-800/20 border-green-800/40">
              <CheckCheck color="green" />
              <AlertTitle className="inline-flex items-center gap-1 text-green-800">
                <span>
                  A nota foi aprovada por{" "}
                  <span className="font-bold text-green-900">
                    {data.approval.name}
                  </span>{" "}
                  em{" "}
                  <span className="font-bold">
                    {format(new Date(data.approval.updatedAt), "PPp")}
                  </span>
                  .
                </span>
              </AlertTitle>
              <AlertDescription className="text-green-800">
                {data.approval.reason ||
                  "Não foram providenciados detalhes adicionais."}
              </AlertDescription>
            </Alert>
          )}
          {data?.approval?.status === "rejected" && (
            <Alert className="bg-red-800/20 border-red-800/40">
              <AlertTriangleIcon color="darkred" className="size-5" />
              <AlertTitle className="inline-flex items-center gap-1 text-red-800">
                <span>
                  A nota foi rejeitada por {data.approval.name} em{" "}
                  {format(new Date(data.approval.updatedAt), "PPp")}.
                </span>
              </AlertTitle>
              <AlertDescription className="text-red-800">
                {data.approval.reason}
              </AlertDescription>
            </Alert>
          )}

          <div className="flex items-center justify-between">
            <div className="flex gap-2">
              {data.links.pdf && (
                <DownloadTooltip
                  tooltip="Baixar como PDF (DANFE)"
                  link={data.links.pdf}
                  align="start"
                >
                  <Button size="icon" variant="outline">
                    <FileText />
                  </Button>
                </DownloadTooltip>
              )}

              {data.links.xml && (
                <DownloadTooltip
                  tooltip="Baixar como XML"
                  link={data.links.xml}
                >
                  <Button size="icon" variant="outline">
                    <FileCode />
                  </Button>
                </DownloadTooltip>
              )}
            </div>
          </div>
        </div>

        {isPdfAvailable ? (
          <div className="flex-grow relative">
            <embed
              src={`${data.links.pdf}#zoom=85`}
              className="absolute inset-0 w-full h-full"
              title="pdf"
              type="application/pdf"
            />
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center p-8 text-center">
            <FileX className="w-16 h-16 text-gray-400 mb-4" />
            <p className="text-lg font-medium text-gray-700">
              Esta nota ainda não foi processada em nosso sistema, tente
              novamente mais tarde.
            </p>
          </div>
        )}
      </div>
    </DialogContent>
  );
}
