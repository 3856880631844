import { useMultiStepFormContext } from "@/hooks/use-multistep.ts";
import { FormFieldSimple } from "@/components/ui/form.tsx";
import { Button } from "@/components/ui/button.tsx";
import { useMutation } from "@tanstack/react-query";
import { fetchApi, getApiData } from "@/lib/api.ts";
import { getBase64 } from "@/lib/utils.ts";
import { Input } from "@/components/ui/input.tsx";
import { Box } from "@/components/tw-ui/box.tsx";
import { PendingButton } from "@/components/pending-button.tsx";
import { Skeleton } from "@/components/ui/skeleton.tsx";
import { formatCNPJ } from "@shared/format.ts";
import { format } from "date-fns";
import { Badge } from "@/components/ui/badge.tsx";
import { Scroll } from "@phosphor-icons/react";
import { Separator } from "@/components/ui/separator.tsx";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert.tsx";

type ValidateCertificateResponse = {
  name?: string;
  document?: string;
  expiration: string;
  isExpired: boolean;
};

export function CreateCompanyStep3() {
  const { form, nextStep } = useMultiStepFormContext();

  const { mutateAsync: validateCertificate, isPending } = useMutation({
    mutationKey: ["/certificates/validate"],
    mutationFn: (data: { certificate: string; password: string }) =>
      fetchApi("/certificates/validate", {
        method: "POST",
        data: {
          certificate: data.certificate,
          certificatePassword: data.password,
        },
      }).then(getApiData<ValidateCertificateResponse>),
    onSuccess: (res) => {
      form.setValue("certificate.certificateDocument", res.document);
      form.setValue("certificate.certificateExpiration", res.expiration);
    },
  });

  const certificate = form.watch("certificate.certificate");
  const certificatePassword = form.watch("certificate.certificatePassword");

  const certificateDocument = form.watch("certificate.certificateDocument");
  const certificateExpiration = form.watch("certificate.certificateExpiration");

  const isExpired =
    certificateExpiration && new Date(certificateExpiration) < new Date();

  const isCertificateValid = certificateDocument && certificateExpiration;

  return (
    <div className="flex flex-col gap-4 h-full">
      <div className="flex flex-col gap-4 flex-wrap flex-1">
        <Alert variant="warning">
          <AlertTitle>Segurança dos dados</AlertTitle>
          <AlertDescription>
            Para utilizar o portal de notas fiscais, é necessário ter um
            certificado digital do{" "}
            <span className="font-semibold">tipo A1 (PFX)</span> válido.
            <br />
            Utilizaremos o certificado para{" "}
            <span className="font-semibold">
              comunicar com os provedores de nota fiscal, municipais e
              estaduais.
            </span>{" "}
            <br />O certificado será armazenado em nossos servidores de forma
            criptografada, podendo ser removido a qualquer momento na aba de
            edição de empresa.
          </AlertDescription>
        </Alert>

        <Separator />

        <Box className="flex flex-col gap-4">
          <div className="flex gap-2 justify-between flex-wrap">
            <FormFieldSimple
              name="certificate.certificate"
              label="Certificado digital A1 - (PFX)"
              description="Utilizaremos o certificado digital para comunicar com os provedores de nota fiscal, sejam NF-e ou NFS-e"
              className="basis-[calc(50%-0.5rem)]"
              required={true}
              render={({ field: { value: _value, ...field } }) => (
                <Input
                  {...field}
                  type="file"
                  accept=".pfx"
                  onChange={async (e) => {
                    const file = e.target.files?.[0];

                    form.setValue("certificate.certificatePassword", "");
                    form.setValue("certificate.certificateDocument", "");
                    form.setValue("certificate.certificateExpiration", "");

                    if (!file) {
                      field.onChange("");
                      return;
                    }

                    const base64 = await getBase64(file);
                    field.onChange(base64);
                  }}
                />
              )}
            />
            <FormFieldSimple
              name="certificate.certificatePassword"
              label="Senha do certificado"
              description="Senha cadastrada no momento da emissão do certificado, entre 4 a 8 dígitos com letras e números"
              required={true}
              className="basis-[calc(50%-0.5rem)]"
              render={({ field }) => (
                <Input
                  {...field}
                  autoComplete="certificate-password"
                  type="password"
                  placeholder="Senha de 4 a 8 dígitos"
                  minLength={4}
                  maxLength={8}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      validateCertificate({
                        certificate: certificate,
                        password: certificatePassword,
                      });
                    }
                  }}
                />
              )}
            />
          </div>
          <PendingButton
            type="button"
            className="w-fit ml-auto"
            variant="default"
            size="sm"
            isPending={isPending}
            onClick={() =>
              validateCertificate({
                certificate: certificate,
                password: certificatePassword,
              })
            }
            disabled={
              !certificate || !certificatePassword || isCertificateValid
            }
          >
            Validar certificado
          </PendingButton>
        </Box>
        <Box className="flex flex-col gap-1">
          {!isCertificateValid && !isPending && (
            <h3>Aguardando validação do certificado...</h3>
          )}
          {isPending && <Skeleton className="flex-1 size-full h-56" />}
          {isCertificateValid && (
            <>
              <div className="inline-flex gap-2 items-center text-lg font-semibold mb-2">
                <h2>Certificado digital</h2>
                <Badge
                  border="dashed"
                  variant={isExpired ? "destructive" : "success"}
                >
                  {isExpired
                    ? "Expirado"
                    : `Válido até ${format(
                        certificateExpiration,
                        "dd/MM/yyyy"
                      )}`}
                </Badge>
              </div>
              <ul className="flex flex-col gap-2 list-none">
                <li className="flex gap-4 items-center">
                  <Scroll className="size-6" />
                  <div className="flex flex-col">
                    <span className="font-semibold">CNPJ Vinculado</span>
                    <span className="text-muted-foreground text-sm">
                      {certificateDocument
                        ? formatCNPJ(certificateDocument)
                        : "Não informado"}
                    </span>
                  </div>
                </li>
              </ul>
            </>
          )}
        </Box>
      </div>
      <div className="ml-auto">
        <Button type="button" onClick={nextStep}>
          Próximo
        </Button>
      </div>
    </div>
  );
}
