import { FormFieldSimple } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { type ExternalCompany } from "@/hooks/use-external-company.ts";
import { useMutation } from "@tanstack/react-query";
import { formatCNPJ, onlyNumbers } from "@shared/format.ts";
import { fetchApi } from "@/lib/api.ts";
import { toast } from "sonner";
import { Button } from "@/components/ui/button.tsx";
import { useMultiStepFormContext } from "@/hooks/use-multistep";
import { PhoneInput } from "@/components/validators/phone-input.tsx";

export function CreateCompanyStep1() {
  const { form, nextStep } = useMultiStepFormContext();

  const { mutate: mutateExternalCompany } = useMutation({
    mutationKey: ["/external/company"],
    mutationFn: async (data: { document: string }) => {
      const safeDocument = onlyNumbers(data.document);

      return fetchApi
        .get(`/external/company/${safeDocument}`)
        .then((res) => res.data.data as ExternalCompany);
    },
    onSuccess: (externalCompany) => {
      form.reset(
        {
          company: {
            document: externalCompany.document,
            name: externalCompany.name,
            fantasyName: externalCompany.fantasyName,
          },
          address: {
            cityCode: externalCompany.address.cityCode,
            city: externalCompany.address.cityName,
            street: externalCompany.address.street,
            complement: externalCompany.address.complement,
            number: externalCompany.address.number,
            district: externalCompany.address.district,
            zipCode: externalCompany.address.zipCode,
            state: externalCompany.address.state,
            stateCode: externalCompany.address.stateCode,
          },
          tributes: {
            tributaryRegime: externalCompany.regime,
            municipalRegistration: "",
            stateRegistration: "",
          },
        },
        {
          keepDefaultValues: true,
        }
      );
    },
    onError: () => {
      toast.error(
        "Dados da empresa não encontrados, certifique-se de que o CNPJ esteja correto."
      );
    },
  });

  return (
    <div className="flex flex-col gap-4 h-full">
      <div className="flex flex-col gap-4 flex-wrap flex-1">
        <FormFieldSimple
          name="company.document"
          label="CNPJ"
          required={true}
          control={form.control}
          render={({ field }) => (
            <Input
              placeholder="00.000.000/0000-00"
              {...field}
              value={formatCNPJ(field.value)}
              onChange={(c) => {
                const d = onlyNumbers(c.target.value);

                if (d.length === 14) {
                  mutateExternalCompany({ document: d });
                }

                field.onChange(c.target.value);
              }}
            />
          )}
        />
        <div className="flex flex-row flex-wrap lg:flex-nowrap gap-2">
          <FormFieldSimple
            name="company.name"
            label="Razão social"
            required={true}
            control={form.control}
            className="w-full"
            render={({ field }) => (
              <Input placeholder="Nome da empresa" {...field} />
            )}
          />
          <FormFieldSimple
            name="company.fantasyName"
            label="Nome fantasia"
            required={true}
            control={form.control}
            className="w-full"
            render={({ field }) => (
              <Input placeholder="Nome da empresa" {...field} />
            )}
          />
        </div>
        <FormFieldSimple
          name="company.phone"
          label="Telefone"
          required={true}
          control={form.control}
          description="Este telefone aparecerá no PDF e XML de notas emitidas por esta empresa."
          render={({ field }) => (
            <PhoneInput placeholder="(00) 0000-0000" {...field} />
          )}
        />
        <FormFieldSimple
          name="company.email"
          label="Email"
          required={true}
          control={form.control}
          description="Este email aparecerá no PDF e XML de notas emitidas por esta empresa."
          render={({ field }) => (
            <Input placeholder="contabilidade@twdocs.com.br" {...field} />
          )}
        />
      </div>
      <div className="ml-auto">
        <Button type="button" onClick={nextStep}>
          Próximo
        </Button>
      </div>
    </div>
  );
}
