import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchApi } from "@/lib/api.ts";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import {
  FormControl,
  FormField,
  FormFieldSimple,
  FormItem,
  FormLabel,
} from "@/components/ui/form.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";
import { Check, ChevronsUpDown, Loader2 } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover.tsx";
import { Button } from "@/components/ui/button.tsx";
import { cn } from "@/lib/utils.ts";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command.tsx";
import {
  type serviceInvoiceSchema,
  ServiceLocationType,
} from "@shared/schemas/invoices/service.ts";
import type { z } from "zod";
import { useFormContext, useWatch } from "react-hook-form";
import { Input } from "@/components/ui/input.tsx";
import { SelectPopover } from "@/components/select-popover.tsx";

type Cities = {
  cities: Array<{ name: string; code: string }>;
};

export function ServiceAddressForm() {
  const form = useFormContext<z.infer<typeof serviceInvoiceSchema>>();

  const { data: dataStates, isLoading: isLoadingStates } = useQuery<{
    states: Array<{ name: string; code: string }>;
  }>({
    queryKey: ["/locations/states"],
  });

  const {
    data: serviceStateCities,
    mutate: mutateCities,
    isPending: isLoadingCities,
  } = useMutation({
    mutationKey: ["/external/state/cities"],
    mutationFn: (state: string) =>
      fetchApi
        .get(`/locations/states/${state}/cities`)
        .then((res) => res.data.data as Cities),
  });

  const [addressLocation] = useWatch({
    control: form.control,
    name: ["service.addressLocation"],
  });

  const addressLocationOther = addressLocation === ServiceLocationType.OTHER;

  return (
    <Card>
      <CardHeader>
        <CardTitle>Local da Prestação do Serviço</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col gap-3">
          <FormFieldSimple
            name="service.addressLocation"
            label="Tipo de endereço"
            render={({ field }) => (
              <SelectPopover
                items={[
                  {
                    label: "1. Utilizar o endereço do prestador",
                    value: ServiceLocationType.ISSUER,
                  },
                  {
                    label: "2. Utilizar o endereço do tomador",
                    value: ServiceLocationType.RECIPIENT,
                  },
                  {
                    label: "3. Outro endereço",
                    value: ServiceLocationType.OTHER,
                  },
                ]}
                value={field.value}
                onValueChange={field.onChange}
              />
            )}
          />
          {addressLocationOther && (
            <div className="flex flex-col xl:grid xl:grid-cols-4 gap-3">
              <div className="space-y-2 w-full">
                <FormLabel required={addressLocationOther}>
                  Estado (UF)
                </FormLabel>
                <Select
                  onValueChange={(value) => {
                    mutateCities(value);
                  }}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Selecione um estado" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {isLoadingStates ? (
                      <SelectItem value="loading" disabled>
                        Carregando...
                      </SelectItem>
                    ) : (
                      !!dataStates &&
                      dataStates.states.map((state, index) => (
                        <SelectItem key={index} value={state.code}>
                          {state.name}
                        </SelectItem>
                      ))
                    )}
                  </SelectContent>
                </Select>
              </div>
              <FormField
                control={form.control}
                name="service.cityCode"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel required={addressLocationOther}>
                      Cidade
                    </FormLabel>
                    {isLoadingCities ? (
                      <div className="flex items-center ml-2">
                        <Loader2 className="mt-2 size-6 animate-spin" />
                      </div>
                    ) : (
                      <Popover>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant="outline"
                              role="combobox"
                              disabled={!serviceStateCities}
                              className={cn(
                                "w-full justify-between",
                                !field.value && "text-muted-foreground"
                              )}
                            >
                              {field.value
                                ? serviceStateCities?.cities.find(
                                    (city) => city.code === field.value
                                  )?.name
                                : "Escolha a cidade"}
                              <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="w-full p-0">
                          <Command>
                            <CommandInput placeholder="Procurar cidade..." />
                            <CommandList>
                              <CommandEmpty>Sem resultados :(</CommandEmpty>
                              <CommandGroup>
                                {serviceStateCities?.cities.map((city) => (
                                  <CommandItem
                                    value={`${city.code}_${city.name}`}
                                    key={city.name}
                                    onSelect={() => {
                                      form.setValue(
                                        "service.cityCode",
                                        city.code
                                      );
                                    }}
                                  >
                                    <Check
                                      className={cn(
                                        "mr-2 h-4 w-4",
                                        city.code === field.value
                                          ? "opacity-100"
                                          : "opacity-0"
                                      )}
                                    />
                                    {city.name}
                                  </CommandItem>
                                ))}
                              </CommandGroup>
                            </CommandList>
                          </Command>
                        </PopoverContent>
                      </Popover>
                    )}
                  </FormItem>
                )}
              />
              <FormFieldSimple
                className="col-span-3"
                control={form.control}
                name="service.address.street"
                label="Logradouro da prestação"
                required={addressLocationOther}
                render={({ field }) => <Input {...field} />}
              />
              <FormFieldSimple
                control={form.control}
                name="service.address.number"
                label="Número"
                required={addressLocationOther}
                render={({ field }) => <Input {...field} />}
              />
              <FormFieldSimple
                control={form.control}
                name="service.address.complement"
                label="Complemento"
                render={({ field }) => <Input {...field} />}
              />
              <FormFieldSimple
                control={form.control}
                name="service.address.district"
                label="Bairro"
                required={addressLocationOther}
                render={({ field }) => <Input {...field} />}
              />
              <FormFieldSimple
                control={form.control}
                name="service.address.zipCode"
                label="CEP"
                required={addressLocationOther}
                render={({ field }) => <Input {...field} />}
              />
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
}
