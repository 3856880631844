import { Button } from "@/components/ui/button.tsx";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import { DownloadIcon } from "lucide-react";
import { fetchApi } from "@/lib/api.ts";
import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";

export function BatchServiceDownloadCsv({ batchId }: { batchId: string }) {
  const { mutate, isPending } = useMutation({
    mutationFn: async () => {
      const response = await fetchApi.get(
        `/invoices/sent/services/batch/${batchId}/download-errors`,
        { responseType: "blob" }
      );
      return response.data as Blob;
    },
    onSuccess: (data) => {
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `batch_${batchId}_errors.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      toast.success("Arquivo baixado com sucesso.");
    },
    onError: () => {
      toast.error("Ocorreu um erro ao baixar o arquivo CSV.");
    },
  });

  return (
    <Tooltip delayDuration={100}>
      <TooltipTrigger asChild>
        <Button
          variant="outline"
          size="xs"
          onClick={() => mutate()}
          disabled={isPending}
        >
          <DownloadIcon className={"mr-2 size-4"} />
          {isPending ? "Baixando..." : "Baixar emissões com erro"}
        </Button>
      </TooltipTrigger>
      <TooltipContent className="max-w-72">
        Os dados das notas fiscais com erro serão exportados para o formato .csv
        para que você possa analisar e corrigir os erros.
      </TooltipContent>
    </Tooltip>
  );
}
