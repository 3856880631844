import { Button } from "@/components/ui/button.tsx";
import { cn } from "@/lib/utils.ts";
import { ChevronsUpDown } from "lucide-react";
import { VirtualizedCombobox } from "@/components/virtualized-combobox.tsx";
import { useLocationCitiesQuery } from "@/hooks/data-access/locations-query.ts";

interface Props {
  value: string;
  onValueChange: (value: string) => void;
  stateCode?: string | null;
}

export function SelectCityMenu({ stateCode, onValueChange, value }: Props) {
  const { data: serviceStateCities, isLoading } =
    useLocationCitiesQuery(stateCode);

  if (!stateCode || isLoading) {
    return (
      <Button
        type="button"
        variant="outline"
        role="combobox"
        className={cn(
          "w-full justify-between",
          !value && "text-muted-foreground"
        )}
        disabled={true}
      >
        {isLoading ? "Carregando..." : "Selecionar cidade"}
        <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
      </Button>
    );
  }

  return (
    <VirtualizedCombobox
      value={value}
      onValueChange={onValueChange}
      options={serviceStateCities?.cities.map((city) => ({
        value: city.code,
        label: city.name,
      }))}
      searchPlaceholder="Pesquisar..."
      height="200px"
    />
  );
}
