import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { vendorAddressSchema, vendorSchema } from "@shared/schemas/vendor.ts";
import { Form } from "@/components/ui/form.tsx";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fetchApi } from "@/lib/api.ts";
import { z } from "zod";
import { useNavigate } from "react-router-dom";
import {
  PageContainer,
  PageContent,
  PageDescription,
  PageHeader,
  PageTitle,
} from "@/components/layout/page.tsx";
import {
  BuildingOffice,
  MapPin,
  SealCheck,
  WarningCircle,
} from "@phosphor-icons/react";
import { CreateVendorStep1 } from "@/pages/vendors/new/steps/step-1.tsx";
import {
  createSchemaFromSteps,
  MultiStepFormContext,
  useMultiStepForm,
} from "@/hooks/use-multistep.ts";
import { CreateVendorStep2 } from "@/pages/vendors/new/steps/step-2.tsx";
import { Card } from "@/components/ui/card.tsx";
import { cn } from "@/lib/utils.ts";
import { Separator } from "@/components/ui/separator.tsx";
import { CreateVendorStep3 } from "@/pages/vendors/new/steps/step-3.tsx";

const steps = [
  {
    name: "company",
    title: "Informações básicas",
    description: "Dados cadastrais do fornecedor",
    component: CreateVendorStep1,
    icon: BuildingOffice,
    schema: vendorSchema,
  },
  {
    name: "address",
    title: "Endereço",
    description: "Endereço do fornecedor",
    component: CreateVendorStep2,
    icon: MapPin,
    schema: vendorAddressSchema,
  },
  {
    name: "evaluation",
    title: "Matriz de Avaliação",
    description: "Matriz de Avaliação do fornecedor",
    component: CreateVendorStep3,
    icon: SealCheck,
    schema: z.object({
      groups: z.array(z.string()),
    }),
  },
] as const;

const createVendorSchema = createSchemaFromSteps(steps);
type FormFields = z.infer<typeof createVendorSchema>;

export function CreateNewVendor() {
  const form = useForm<FormFields>({
    resolver: zodResolver(createVendorSchema),
    mode: "onBlur",
  });

  const vendorCreateForm = useForm<z.infer<typeof createVendorSchema>>({
    resolver: zodResolver(createVendorSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      company: {
        document: "",
        name: "",
        fantasyName: "",
        phone: "",
        email: "",
      },
      address: {
        street: "",
        number: "",
        district: "",
        zipCode: "",
        complement: "",
        cityCode: "",
        stateCode: "",
      },
      evaluation: {
        groups: [],
      },
    },
  });

  const multiStepForm = useMultiStepForm(
    vendorCreateForm,
    createVendorSchema,
    steps,
    true
  );

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationKey: ["/vendors"],
    mutationFn: (data: FormFields) =>
      fetchApi("/vendors", {
        method: "post",
        data: data,
      }),
    onSuccess: async () => {
      form.reset();

      await queryClient.invalidateQueries({
        queryKey: ["/vendors"],
      });

      navigate("/vendors");
    },
  });

  const currentStepIndex = multiStepForm.currentStepIndex;
  const currentStep = steps[currentStepIndex];

  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Novo fornecedor</PageTitle>
        <PageDescription>
          Adicione um novo fornecedor a partir de um CNPJ ou CPF válido.
        </PageDescription>
      </PageHeader>
      <PageContent className="flex flex-col items-center justify-center gap-4">
        <MultiStepFormContext.Provider value={multiStepForm}>
          <Card className="flex flex-col lg:flex-row w-full max-w-6xl min-h-[45rem] overflow-hidden rounded shadow-sm">
            <div className="flex flex-row lg:flex-col lg:max-w-72 w-full flex-wrap">
              {steps.map((step, i) => (
                <button
                  key={i}
                  className={cn(
                    "relative flex items-center gap-2 p-6 text-foreground w-1/2 lg:w-full",
                    currentStepIndex === i &&
                      "bg-primary/15 shadow font-medium",
                    "transition-all duration-200"
                  )}
                  data-active={currentStepIndex === i}
                  onClick={() => multiStepForm.goToStep(i)}
                >
                  <div
                    className={cn(
                      "absolute left-0 bottom-0 w-full h-1 lg:w-1 lg:h-full bg-primary transition-all duration-200",
                      currentStepIndex === i ? "opacity-100" : "opacity-0",
                      "rounded-tl rounded-bl"
                    )}
                  />
                  {"icon" in step && (
                    <step.icon className="h-6 w-6 data-[active]:text-primary" />
                  )}
                  <span className="text-left flex-1">{step.title}</span>
                  {multiStepForm.invalidSteps.includes(step.name) && (
                    <span className="text-red-600 absolute top-1 right-1 text-xs">
                      <WarningCircle className="size-4" />
                    </span>
                  )}
                </button>
              ))}
            </div>
            <div className="w-full h-0.5 lg:w-1 lg:h-full bg-primary/15" />
            <div className="flex flex-col gap-2 p-4 w-full">
              <span className="text-muted-foreground">
                {"description" in currentStep && currentStep.description}
              </span>
              <Separator className="w-full mb-2" />
              <Form {...vendorCreateForm}>
                <form
                  className="h-full"
                  onSubmit={vendorCreateForm.handleSubmit((data) =>
                    mutate(data)
                  )}
                >
                  <currentStep.component />
                </form>
              </Form>
            </div>
          </Card>
        </MultiStepFormContext.Provider>
      </PageContent>
    </PageContainer>
  );
}
