import { z } from "zod";
import { useForm, useFormContext } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Form, FormField, FormFieldSimple } from "@/components/ui/form.tsx";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import { fetchApi } from "@/lib/api.ts";
import { toast } from "sonner";
import { Loader2 } from "lucide-react";
import { Box, BoxTitle } from "@/components/tw-ui/box.tsx";
import { cn } from "@/lib/utils.ts";
import { Input } from "@/components/ui/input.tsx";
import { type ComponentPropsWithoutRef, useId } from "react";
import { Switch } from "@/components/ui/switch.tsx";
import { Label } from "@/components/ui/label.tsx";
import { PendingButton } from "@/components/pending-button.tsx";

const schema = z
  .object({
    sendServiceInvoice: z.boolean().optional().default(false),

    sendProductInvoice: z.boolean().optional().default(false),
    sendProductInvoiceSeries: z.coerce.number().optional(),
    sendProductInvoiceNextNumber: z.coerce.number().optional(),

    receiveProductInvoice: z.boolean().optional().default(false),
  })
  .superRefine((data, ctx) => {
    if (data.sendProductInvoice) {
      if (
        typeof data.sendProductInvoiceSeries === "undefined" ||
        typeof data.sendProductInvoiceNextNumber === "undefined"
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: "Série e próximo número são obrigatórios",
          path: ["sendProductInvoiceSeries", "sendProductInvoiceNextNumber"],
        });
      }
    }
  });

type FormServicesValues = z.infer<typeof schema>;

function ServiceSwitch({
  name,
  label,
  description,
  icon = null,
  ...props
}: {
  name: string;
  label: string;
  description: string;
  icon?: React.ReactNode;
} & ComponentPropsWithoutRef<typeof Switch>) {
  const id = useId();
  const { control } = useFormContext();

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <div className="relative flex w-full items-start gap-2 rounded-lg border border-input p-4 shadow-sm shadow-black/5 has-[[data-state=checked]]:border-primary transition">
            <Switch
              id={`switch-${id}`}
              checked={field.value || false}
              onCheckedChange={field.onChange}
              className="order-1 h-4 w-8 after:absolute after:inset-0 [&_span]:size-3 [&_span]:data-[state=checked]:translate-x-4 rtl:[&_span]:data-[state=checked]:-translate-x-4"
              {...props}
            />
            <div className="flex grow items-center gap-3">
              {icon}
              <div className="grid grow gap-2">
                <Label
                  htmlFor={`switch-${id}`}
                  className="text-sm font-semibold"
                >
                  {label}
                </Label>
                <p className="text-xs text-muted-foreground">{description}</p>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}

export function FormServices() {
  const { document } = useParams();

  const { data, isLoading } = useQuery<FormServicesValues>({
    queryKey: [`/companies/${document}/services`],
    enabled: !!document,
  });

  if (isLoading || !data || !document) {
    return <Loader2 size={24} className="animate-spin" />;
  }

  return <FormServicesCheckbox data={data} document={document} />;
}

function FormServicesCheckbox({
  data,
  document,
}: {
  data: FormServicesValues;
  document: string;
}) {
  const queryClient = useQueryClient();

  const { mutateAsync, isPending } = useMutation({
    mutationKey: [`/companies/${document}/services`],
    mutationFn: (values: FormServicesValues) => {
      return fetchApi(`/companies/${document}/services`, {
        method: "put",
        data: {
          services: values,
        },
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [`/companies/${document}/services`],
      });

      toast.success("Configurações atualizadas com sucesso!");
    },
  });

  const form = useForm<FormServicesValues>({
    resolver: zodResolver(schema),
    values: data,
  });

  const formData = form.watch();

  const onSubmit = async (values: FormServicesValues) => {
    await mutateAsync(values);
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <Box className="flex flex-col gap-2 pt-0">
          <BoxTitle>Nota Fiscal de Serviço Eletrônica</BoxTitle>
          <div className="flex flex-col gap-4">
            <ServiceSwitch
              name="sendServiceInvoice"
              label="NFSe - Emissão"
              description="Habilite a emissão de NFSes, facilitando o registro e garantindo conformidade fiscal."
              disabled={isPending}
            />
            <div
              className={cn(
                "flex gap-2 overflow-hidden max-h-0 transition-all duration-200",
                formData.sendServiceInvoice && "max-h-[100px]"
              )}
            >
              <p>
                <span>
                  Você pode adicionar a credencial de login na prefeitura na aba
                </span>
                <Link
                  to={`/companies/${document}/credentials`}
                  target="_blank"
                  className="text-primary underline-offset-4 hover:underline ml-1 font-medium"
                >
                  Credenciais.
                </Link>
              </p>
            </div>
          </div>
        </Box>
        <Box className="flex flex-col gap-2 pt-0">
          <BoxTitle>Nota Fiscal Eletrônica</BoxTitle>
          <div className="flex flex-col gap-4">
            <ServiceSwitch
              name="receiveProductInvoice"
              label="NFe - Captura"
              description="Receba automaticamente NFes emitidas contra seu CNPJ, sem precisar entrar em contato com o fornecedor."
              disabled={isPending}
            />
            <div className="flex flex-col gap-4">
              <ServiceSwitch
                name="sendProductInvoice"
                label="NFe - Emissão"
                description="Utilize o Emissor NFe mais completo e rápido do Brasil para agilizar seu processo de emissão de notas fiscais."
                disabled={isPending}
              />
              <div
                className={cn(
                  "flex gap-2 overflow-hidden max-h-0 transition-all duration-200",
                  formData.sendProductInvoice && "max-h-[100px]"
                )}
              >
                <FormFieldSimple
                  name="sendProductInvoiceSeries"
                  label="Série NFe"
                  required={true}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="number"
                      min={0}
                      disabled={isPending}
                    />
                  )}
                />
                <FormFieldSimple
                  name="sendProductInvoiceNextNumber"
                  label="Próximo número"
                  required={true}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="number"
                      min={0}
                      defaultValue={0}
                      disabled={isPending}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </Box>

        <div className="flex justify-end">
          <PendingButton isPending={isPending}>
            Salvar configurações
          </PendingButton>
        </div>
      </form>
    </Form>
  );
}
