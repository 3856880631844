import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import type { serviceInvoiceSchema } from "@shared/schemas/invoices/service.ts";
import { useFormContext } from "react-hook-form";
import type { z } from "zod";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form.tsx";
import { DocumentInput } from "@/components/validators/document-input.tsx";
import { onlyNumbers } from "@shared/format.ts";
import { isCNPJ, isCPF } from "validation-br";
import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchApi } from "@/lib/api.ts";
import type { ExternalCompany } from "@/hooks/use-external-company.ts";
import { Input } from "@/components/ui/input.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select.tsx";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover.tsx";
import { Button } from "@/components/ui/button.tsx";
import { cn } from "@/lib/utils.ts";
import { Check, ChevronsUpDown, Loader2 } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command.tsx";

type Cities = {
  cities: Array<{ name: string; code: string }>;
};

export function RecipientForm() {
  const form = useFormContext<z.infer<typeof serviceInvoiceSchema>>();
  const { data: dataStates, isLoading: isLoadingStates } = useQuery<{
    states: Array<{ name: string; code: string }>;
  }>({
    queryKey: ["/locations/states"],
    enabled: false,
  });

  const {
    data: stateCities,
    mutate: mutateCities,
    isPending: isLoadingCities,
  } = useMutation({
    mutationKey: ["/external/state/cities"],
    mutationFn: (state: string) =>
      fetchApi
        .get(`/locations/states/${state}/cities`)
        .then((res) => res.data.data as Cities),
  });

  const { mutate: mutateRecipientCompany } = useMutation({
    mutationKey: ["/external/company"],
    mutationFn: (data: { document: string }) =>
      fetchApi
        .get("/external/company/" + onlyNumbers(data.document))
        .then((res) => res.data.data as ExternalCompany),
    onSuccess: (data) => {
      form.setValue("recipient.companyName", data.name);
      form.setValue("recipient.address.cityCode", data.address.cityCode);
      form.setValue("recipient.address.street", data.address.street);
      form.setValue("recipient.address.complement", data.address.complement);
      form.setValue("recipient.address.number", data.address.number);
      form.setValue("recipient.address.district", data.address.district);
      form.setValue("recipient.address.zipCode", data.address.zipCode);
      form.setValue("recipient.address.state", data.address.state);
      mutateCities(data.address.state);
    },
  });

  const usingCpf = isCPF(form.watch("recipient.document"));
  const documentType = usingCpf ? "CPF" : "CNPJ";
  const name = usingCpf ? "Nome Completo" : "Razão Social";

  return (
    <Card>
      <CardHeader>
        <CardTitle>Tomador do Serviço</CardTitle>
      </CardHeader>
      <CardContent>
        <div
          className={
            "flex flex-col flex-wrap gap-2 lg:grid xl:grid-cols-4 lg:grid-cols-2"
          }
        >
          <FormField
            control={form.control}
            name="recipient.document"
            render={({ field }) => (
              <FormItem>
                {/* TODO: Maybe add a search button to push a modal to search for a vendor */}
                <FormLabel required>Documento (CNPJ/CPF)</FormLabel>
                <FormControl>
                  <DocumentInput
                    {...field}
                    format={documentType}
                    onChange={(value) => {
                      const companyDocument = onlyNumbers(value);
                      if (isCNPJ(companyDocument)) {
                        mutateRecipientCompany({ document: companyDocument });
                      }

                      field.onChange(value);
                    }}
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="recipient.companyName"
            render={({ field }) => (
              <FormItem className="xl:col-span-3">
                <FormLabel required>{name}</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="recipient.municipalRegistration"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Inscrição Municipal</FormLabel>
                <FormControl>
                  <Input {...field} disabled={usingCpf} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="recipient.stateRegistration"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Inscrição Estadual</FormLabel>
                <FormControl>
                  <Input {...field} disabled={usingCpf} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="recipient.email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input type="email" {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="recipient.phone"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Telefone</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="recipient.address.street"
            render={({ field }) => (
              <FormItem className="xl:col-span-3">
                <FormLabel required>Logradouro</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="recipient.address.number"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Número</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="recipient.address.complement"
            render={({ field }) => (
              <FormItem className="xl:col-span-2">
                <FormLabel>Complemento</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="recipient.address.district"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Bairro</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="recipient.address.zipCode"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>CEP</FormLabel>
                <FormControl>
                  <Input {...field} />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="recipient.address.state"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Estado (UF)</FormLabel>
                <Select
                  onValueChange={(value) => {
                    form.setValue("recipient.address.cityCode", "");
                    mutateCities(value);
                    field.onChange(value);
                  }}
                  value={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Selecione um estado" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {isLoadingStates ? (
                      <SelectItem value="loading" disabled>
                        Carregando...
                      </SelectItem>
                    ) : (
                      !!dataStates &&
                      dataStates.states.map((state, index) => (
                        <SelectItem key={index} value={state.code}>
                          {state.name}
                        </SelectItem>
                      ))
                    )}
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="recipient.address.cityCode"
            render={({ field }) => (
              <FormItem>
                <FormLabel required>Cidade</FormLabel>
                {isLoadingCities ? (
                  <div className="flex items-center ml-2">
                    <Loader2 className="mt-2 size-6 animate-spin" />
                  </div>
                ) : (
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          role="combobox"
                          disabled={!stateCities}
                          className={cn(
                            "w-full justify-between",
                            !field.value && "text-muted-foreground"
                          )}
                        >
                          {field.value
                            ? stateCities?.cities.find(
                                (city) => city.code === field.value
                              )?.name
                            : "Escolha a cidade"}
                          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-full p-0">
                      <Command>
                        <CommandInput placeholder="Procurar cidade..." />
                        <CommandList>
                          <CommandEmpty>Sem resultados :(</CommandEmpty>
                          <CommandGroup>
                            {stateCities?.cities.map((city) => (
                              <CommandItem
                                value={`${city.code}_${city.name}`}
                                key={city.name}
                                onSelect={() => {
                                  form.setValue(
                                    "recipient.address.cityCode",
                                    city.code
                                  );
                                }}
                              >
                                <Check
                                  className={cn(
                                    "mr-2 h-4 w-4",
                                    city.code === field.value
                                      ? "opacity-100"
                                      : "opacity-0"
                                  )}
                                />
                                {city.name}
                              </CommandItem>
                            ))}
                          </CommandGroup>
                        </CommandList>
                      </Command>
                    </PopoverContent>
                  </Popover>
                )}
              </FormItem>
            )}
          />
        </div>
      </CardContent>
    </Card>
  );
}
