import { PatternFormat } from "react-number-format";
import { forwardRef, type ComponentPropsWithoutRef } from "react";
import { Input } from "@/components/ui/input.tsx";

interface Props
  extends Omit<
    ComponentPropsWithoutRef<"input">,
    "ref" | "value" | "onChange" | "type" | "defaultValue"
  > {
  value: string | number | null;
  onChange: (value: string) => void;
  format: "CPF" | "CNPJ";
}

const documentFormats = {
  CPF: "###.###.###-##",
  CNPJ: "##.###.###/####-##",
} as const;

export const DocumentInput = forwardRef(
  (
    { value, onChange, format, ...props }: Props,
    ref: React.ForwardedRef<typeof PatternFormat>
  ) => {
    if (!["CPF", "CNPJ"].includes(format)) {
      throw new Error("Invalid input format");
    }

    return (
      <PatternFormat
        format={documentFormats[format]}
        mask={"_"}
        onValueChange={(value) => onChange(value.value || "")}
        customInput={Input}
        value={value || ""}
        type="text"
        getInputRef={ref}
        {...props}
      />
    );
  }
);
