import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import type { ComponentPropsWithoutRef } from "react";

export function ComponentWithTooltip({
  children,
  tooltip,
  ...props
}: {
  tooltip: string;
} & ComponentPropsWithoutRef<typeof TooltipContent>) {
  return (
    <Tooltip delayDuration={300}>
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <TooltipContent className="max-w-64" {...props}>
        <div className="text-muted-foreground text-xs">{tooltip}</div>
      </TooltipContent>
    </Tooltip>
  );
}
