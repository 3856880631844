import {
  PageContainer,
  PageContent,
  PageHeader,
  PageTitle,
} from "@/components/layout/page.tsx";
import { useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import {
  ChatDots,
  CheckCircle,
  Clock,
  FileText,
  Hammer,
  ThumbsDown,
  ThumbsUp,
  UserCheck,
  XCircle,
} from "@phosphor-icons/react";
import { Button } from "@/components/ui/button.tsx";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import { Tabs, TabsContent, TabsTrigger } from "@/components/ui/tabs";
import { TabsList } from "@/components/ui/tabs.tsx";
import { Avatar, AvatarFallback } from "@/components/ui/avatar.tsx";
import { ScrollArea } from "@/components/ui/scroll-area.tsx";
import { Textarea } from "@/components/ui/textarea.tsx";
import { CircleX, FileX, Send } from "lucide-react";
import { Fragment } from "react";
import { cn } from "@/lib/utils.ts";
import { fetchApi, getApiData } from "@/lib/api.ts";
import {
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { format } from "date-fns";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Form, FormFieldSimple } from "@/components/ui/form.tsx";
import { evaluationStatus } from "@/pages/evaluation/util.ts";
import { useUser } from "@/hooks/use-user.ts";
import type { UserGroup } from "@shared/types/user.ts";
import { PendingButton } from "@/components/pending-button.tsx";
import { toast } from "sonner";

type EvaluationApprover = {
  name: string;
  role: string;
  status: "approved" | "pending" | "rejected";
  date?: string;
  type: "sector" | "legal" | "finance";
  rejectReason?: string;
};

type EvaluationStep = {
  status: "DI" | "SR" | "VC" | "LF" | "DP" | "DPG";
  completed: boolean;
  date?: string;
  groups: string[];
};

interface EvaluationComment {
  user: string;
  comment: string;
  createdAt: string;
}

interface EvaluationDetails {
  steps: EvaluationStep[];
  approves: EvaluationApprover[];
  lastUpdateAt: string;
}

const commentSchema = z.object({
  comment: z.string().min(1, "Por favor, insira um comentário."),
});

const tabSteps = {
  SR: "sector",
  VC: "legal",
  LF: "finance",
};

const canUserGroupApprove = (
  userGroups: UserGroup[] | undefined,
  step: EvaluationStep | undefined
) => {
  if (!userGroups || !step) {
    return false;
  }

  return (
    !step.completed &&
    userGroups.some((group) => step.groups.includes(group.id))
  );
};

//TODO: Update with the corrects endpoints

export function DocumentView() {
  const { invoiceId } = useParams();
  const { data: userData } = useUser();
  const queryClient = useQueryClient();

  const { data: invoiceDetails } = useSuspenseQuery<{
    links: {
      pdf: string | null;
      xml: string | null;
    };
  }>({
    queryKey: [`/invoices/received/product/${invoiceId}`],
  });

  const { data: evaluationDetails } = useSuspenseQuery({
    queryKey: ["retrieve-evaluation-details", invoiceId],
    queryFn: () =>
      fetchApi
        .get(`/evaluation/document/${invoiceId}`)
        .then(getApiData<EvaluationDetails>),
  });

  const { data: comments } = useQuery({
    queryKey: ["retrieve-comments", invoiceId],
    queryFn: () =>
      fetchApi
        .get(`/evaluation/document/${invoiceId}/comments`)
        .then(
          getApiData<{
            comments: EvaluationComment[];
          }>
        )
        .then((r) => r.comments),
  });

  const { mutate, isPending } = useMutation({
    mutationKey: ["evaluate-document"],
    mutationFn: (data: {
      status: "approved" | "rejected";
      groupId: string;
    }) => {
      return fetchApi.post(`/evaluation/document/${invoiceId}`, data);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["retrieve-evaluation-details", invoiceId],
      });
      toast.success("Operação concluída com sucesso.");
    },
  });

  const { mutate: addComment, isPending: isPendingComment } = useMutation({
    mutationFn: async (data: { comment: string }) => {
      return fetchApi.post(`/evaluation/document/${invoiceId}/comments`, data);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["retrieve-comments", invoiceId],
      });
      toast.success("Comentário adicionado com sucesso.");
    },
  });

  const form = useForm<z.infer<typeof commentSchema>>({
    resolver: zodResolver(commentSchema),
    defaultValues: {
      comment: "",
    },
  });

  const submitComment = form.handleSubmit((data) => {
    addComment(data);
  });

  const currentStep = evaluationDetails?.steps.find((step) => !step.completed);
  const allStepsDone = evaluationDetails?.steps.every((step) => step.completed);

  const isAvailableToApprove = canUserGroupApprove(
    userData?.groups,
    currentStep
  );
  const isPdfAvailable = invoiceDetails?.links.pdf;
  const evaluate = (status: "approved" | "rejected") => {
    mutate({
      status: status,
      groupId: currentStep?.groups[0] ?? "",
    });
  };
  return (
    <PageContainer>
      <PageHeader>
        <PageTitle>Matriz de avaliação</PageTitle>
      </PageHeader>
      <PageContent>
        {!evaluationDetails && (
          <div className="flex items-center justify-center h-full gap-x-1.5">
            <CircleX className="size-5 text-red-600" />
            <span className="text-sm text-muted-foreground">
              Não foi possível carregar os dados deste documento. Por favor,
              tente novamente mais tarde.
            </span>
          </div>
        )}
        {evaluationDetails && (
          <div className="flex flex-col gap-4 p-4 max-w-7xl mx-auto">
            {/* Document Progress */}
            <Card>
              <CardHeader>
                <CardTitle className="text-lg flex items-center gap-2">
                  <Clock className="h-5 w-5" />
                  Progresso das tarefas
                </CardTitle>
              </CardHeader>
              <CardContent>
                <div className="flex items-center space-x-4">
                  {evaluationDetails.steps.map((step, index, arr) => (
                    <Fragment key={step.status}>
                      <div className="flex flex-col items-center relative">
                        <Tooltip>
                          <TooltipTrigger>
                            <div
                              className={`w-8 h-8 rounded-full flex items-center justify-center ${
                                step.completed ? "bg-green-500" : "bg-gray-300"
                              }`}
                            >
                              {step.completed ? (
                                <CheckCircle className="h-5 w-5 text-white" />
                              ) : (
                                <Clock className="h-5 w-5 text-white" />
                              )}
                            </div>
                          </TooltipTrigger>
                          <TooltipContent>
                            <p className={cn(step.completed && "font-medium")}>
                              {evaluationStatus[step.status]}
                            </p>
                            {step.date && (
                              <p className="text-xs">
                                Completado em {format(step.date, "dd/MM/yyyy")}
                              </p>
                            )}
                          </TooltipContent>
                        </Tooltip>
                        <p
                          className={`text-xs mt-1 ${
                            step.completed ? "text-green-500" : "text-gray-500"
                          }`}
                        >
                          {step.status}
                        </p>
                      </div>
                      {index < arr.length - 1 && (
                        <div className="flex-1 h-1 mx-2">
                          <div
                            className="h-full w-full rounded-full"
                            style={{
                              background: arr[index].completed
                                ? arr[index + 1].completed
                                  ? "linear-gradient(to right, #22c55e, #22c55e)"
                                  : "linear-gradient(to right, #22c55e, #d1d5db)"
                                : "linear-gradient(to right, #d1d5db, #d1d5db)",
                            }}
                          ></div>
                        </div>
                      )}
                    </Fragment>
                  ))}
                </div>
              </CardContent>
            </Card>

            {/* Document View */}
            <div className="grid gap-4 lg:grid-cols-4">
              <Card className="lg:col-span-3">
                <CardHeader>
                  <CardTitle className="text-lg flex items-center gap-2">
                    <FileText className="h-5 w-5" />
                    Pré-visualização do documento
                  </CardTitle>
                </CardHeader>
                <CardContent>
                  {isPdfAvailable ? (
                    <embed
                      src={`${invoiceDetails.links.pdf}#zoom=85`}
                      className="w-full h-[400px]"
                      title="pdf"
                      type="application/pdf"
                    />
                  ) : (
                    <div className="flex flex-col items-center justify-center p-8 text-center">
                      <FileX className="w-16 h-16 text-gray-400 mb-4" />
                      <p className="text-lg font-medium text-gray-700">
                        Documento não disponível para visualização neste
                        momento.
                      </p>
                    </div>
                  )}
                </CardContent>
              </Card>

              {/* Document Actions */}
              <Card>
                <CardHeader>
                  <CardTitle className="text-lg flex items-center gap-2">
                    <Hammer className="mr-2 h-4 w-4" />
                    Ações
                  </CardTitle>
                </CardHeader>
                <CardContent className="flex flex-col gap-4">
                  <div className="mb-4">
                    <h4 className="mb-2 text-sm font-medium">Tarefa Atual</h4>
                    <div className="rounded-md bg-yellow-100 p-3 text-yellow-800">
                      <p className="text-sm font-medium">
                        {allStepsDone
                          ? "Avaliação concluída"
                          : currentStep
                            ? evaluationStatus[currentStep.status]
                            : "Tarefa desconhecida"}
                      </p>
                      <p className="text-xs">
                        Última atualização:{" "}
                        {format(evaluationDetails.lastUpdateAt, "dd/MM/yyyy")}
                      </p>
                    </div>
                  </div>
                  <PendingButton
                    className="w-full"
                    variant="default"
                    onClick={() => evaluate("approved")}
                    disabled={!isAvailableToApprove}
                    isPending={isPending}
                  >
                    <ThumbsUp className="mr-2 h-4 w-4" /> Aprovar
                  </PendingButton>
                  <PendingButton
                    className="w-full"
                    variant="destructive"
                    onClick={() => evaluate("rejected")}
                    disabled={!isAvailableToApprove}
                    isPending={isPending}
                  >
                    <ThumbsDown className="mr-2 h-4 w-4" /> Rejeitar
                  </PendingButton>
                  <Button
                    className="w-full"
                    variant="secondary"
                    disabled={true}
                  >
                    <XCircle className="mr-2 h-4 w-4" /> Cancelar documento
                  </Button>
                </CardContent>
              </Card>
            </div>

            {/* Approvers */}
            <Card>
              <CardHeader>
                <CardTitle className="text-lg flex items-center gap-2">
                  <UserCheck className="h-5 w-5" />
                  Aprovadores
                </CardTitle>
              </CardHeader>
              <CardContent className="flex flex-col gap-4">
                <Tabs
                  defaultValue={
                    tabSteps[currentStep?.status as keyof typeof tabSteps]
                  }
                >
                  <TabsList className="grid w-full grid-cols-3">
                    <TabsTrigger value="sector">Setor responsável</TabsTrigger>
                    <TabsTrigger value="legal">Contabilidade</TabsTrigger>
                    <TabsTrigger value="finance">Financeiro</TabsTrigger>
                  </TabsList>
                  {["sector", "finance", "legal"].map((tab) => {
                    const area = evaluationDetails.approves.filter(
                      (data) => data.type === tab
                    );

                    return (
                      <TabsContent key={tab} value={tab}>
                        <div className="space-y-4">
                          {area.map((approver, index) => (
                            <div
                              key={index}
                              className={cn(
                                `flex items-center justify-between p-2 rounded-lg`,
                                {
                                  "bg-green-50": approver.status === "approved",
                                  "bg-gray-50": approver.status === "pending",
                                  "bg-red-50": approver.status === "rejected",
                                }
                              )}
                            >
                              <div className="flex items-center space-x-4">
                                <Avatar>
                                  <AvatarFallback>
                                    {approver.name[0]}
                                  </AvatarFallback>
                                </Avatar>
                                <div>
                                  <p className="text-sm font-medium">
                                    {approver.name}
                                  </p>
                                  <p className="text-xs text-muted-foreground">
                                    {approver.role}
                                  </p>
                                </div>
                              </div>
                              <div className="flex items-center">
                                {approver.status === "approved" &&
                                  approver.date && (
                                    <>
                                      <CheckCircle className="mr-2 h-4 w-4 text-green-500" />
                                      <p className="text-xs text-muted-foreground">
                                        Aprovou o documento em{" "}
                                        {format(
                                          approver.date,
                                          "dd/MM/yyyy 'às' HH:mm"
                                        )}
                                      </p>
                                    </>
                                  )}
                                {approver.status === "rejected" &&
                                  approver.date && (
                                    <>
                                      <XCircle className="mr-2 h-4 w-4 text-red-500" />
                                      <p className="text-xs text-muted-foreground">
                                        Rejeitou o documento em{" "}
                                        {format(
                                          approver.date,
                                          "dd/MM/yyyy 'às' HH:mm"
                                        )}
                                      </p>
                                      <Tooltip>
                                        <TooltipTrigger>
                                          <ChatDots className="ml-2 h-4 w-4 text-blue-500" />
                                        </TooltipTrigger>
                                        <TooltipContent className="max-w-64">
                                          <p className="text-xs">
                                            {approver.rejectReason}
                                          </p>
                                        </TooltipContent>
                                      </Tooltip>
                                    </>
                                  )}
                                {approver.status === "pending" && (
                                  <>
                                    <Clock className="mr-2 h-4 w-4 text-blue-500" />
                                    <p className="text-xs text-muted-foreground">
                                      Aguardando aprovação
                                    </p>
                                  </>
                                )}
                              </div>
                              {}
                            </div>
                          ))}
                        </div>
                      </TabsContent>
                    );
                  })}
                </Tabs>
              </CardContent>
            </Card>

            {/* Comments */}
            <Card>
              <CardHeader>
                <CardTitle className="text-lg flex items-center gap-2">
                  <FileText className="h-5 w-5" />
                  Comentários ({comments?.length})
                </CardTitle>
              </CardHeader>
              <CardContent>
                <ScrollArea className="h-60 w-full rounded-md border p-4">
                  {comments?.map((comment) => (
                    <div
                      key={comment.createdAt}
                      className={`mb-4 last:mb-0 p-3 rounded-lg bg-blue-50`}
                    >
                      <div className="flex items-center space-x-2">
                        <Avatar>
                          <AvatarFallback>{comment.user[0]}</AvatarFallback>
                        </Avatar>
                        <div>
                          <p className="text-sm font-medium">{comment.user}</p>
                          <p className="text-xs text-muted-foreground">
                            {format(comment.createdAt, "dd/MM/yyyy 'às' HH:mm")}
                          </p>
                        </div>
                      </div>
                      <p className="mt-2 text-sm">{comment.comment}</p>
                    </div>
                  ))}
                </ScrollArea>
                <Form {...form}>
                  <form onSubmit={submitComment}>
                    <div className="mt-4 flex space-x-2">
                      <FormFieldSimple
                        control={form.control}
                        name="comment"
                        className="flex-1"
                        render={({ field }) => (
                          <Textarea
                            {...field}
                            placeholder="Adicionar um comentário..."
                            className="flex-grow"
                          />
                        )}
                      />
                      <PendingButton
                        isPending={isPendingComment}
                        type="submit"
                        className="mt-5"
                      >
                        <Send className="h-4 w-4" />
                      </PendingButton>
                    </div>
                  </form>
                </Form>
              </CardContent>
            </Card>
          </div>
        )}
      </PageContent>
    </PageContainer>
  );
}
