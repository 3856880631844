import { fetchApi, getApiData } from "@/lib/api.ts";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

type States = {
  states: Array<{ name: string; code: string }>;
};

export function useLocationStates() {
  return useQuery({
    queryKey: ["/locations/states"],
    queryFn: () => fetchApi.get(`/locations/states`).then(getApiData<States>),
    staleTime: Infinity,
  });
}

type Cities = {
  cities: Array<{ name: string; code: string }>;
};

export function useLocationCitiesQuery(stateCode?: string | null) {
  return useQuery({
    queryKey: ["/external/state/cities", stateCode],
    queryFn: () =>
      fetchApi
        .get(`/locations/states/${stateCode}/cities`)
        .then(getApiData<Cities>),
    enabled: !!stateCode,
    placeholderData: keepPreviousData,
    initialData: {
      cities: [],
    },
  });
}
