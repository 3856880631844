import { FormFieldSimple } from "@/components/ui/form.tsx";
import { Button } from "@/components/ui/button.tsx";
import { useMultiStepFormContext } from "@/hooks/use-multistep.ts";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card.tsx";
import { MultipleSelector } from "@/multiselect.tsx";
import { useQuery } from "@tanstack/react-query";
import { fetchApi, getApiData } from "@/lib/api.ts";

export function CreateVendorStep3() {
  const { form, nextStep } = useMultiStepFormContext();

  const { data: groups } = useQuery({
    queryKey: ["/groups"],
    queryFn: async () => {
      return fetchApi
        .get("/groups")
        .then(
          getApiData<{
            groups: { id: string; name: string; memberCount: number }[];
          }>
        )
        .then((r) => r.groups);
    },
    initialData: [],
  });

  return (
    <div className="flex flex-col gap-4 h-full">
      <div className="flex flex-col gap-4 flex-wrap flex-1">
        <Card className="shadow-md bg-primary/10">
          <CardHeader>
            <CardTitle>O que é a Matriz de Avaliação?</CardTitle>
          </CardHeader>
          <CardContent>
            <p>
              A{" "}
              <span className="font-raleway font-bold">
                Matriz de Avaliação
              </span>{" "}
              do <span className="font-raleway font-bold">TW Docs</span> é uma
              funcionalidade poderosa que permite definir quais grupos de
              usuários estão autorizados a classificar os documentos emitidos
              pelos seus fornecedores.
            </p>
            <p>
              Essa classificação é essencial para garantir maior controle no
              pagamento de documentos.
            </p>
          </CardContent>
          <CardFooter>
            <p>
              Ainda tem dúvidas? Consulte a nossa{" "}
              <a
                href="https://twdocs.com.br/blog/matriz-de-aprovacao"
                target="_blank"
                rel="noreferrer"
                className="text-primary underline"
              >
                base de conhecimento
              </a>{" "}
              para mais detalhes.
            </p>
          </CardFooter>
        </Card>
        <div className="flex flex-col gap-4">
          <FormFieldSimple
            name="evaluation.groups"
            label="Grupos"
            description="Selecione os grupos que você deseja adicionar ao seu fornecedor."
            control={form.control}
            render={({ field }) => (
              <MultipleSelector
                value={field.value}
                onValueChange={field.onChange}
                emptyIndicator="Nenhum grupo disponível, você pode criar um novo em Sistema > Grupos."
                loadingIndicator="Carregando grupos..."
                items={groups.map((group) => ({
                  value: group.id,
                  label: `${group.name} (${group.memberCount})`,
                }))}
              />
            )}
          />
        </div>
      </div>
      <div className="ml-auto">
        <Button type="button" onClick={nextStep}>
          Próximo
        </Button>
      </div>
    </div>
  );
}
